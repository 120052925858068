import React, { useState, useEffect } from 'react'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { depositUserBalance, getUsers, withdrawUserBalance } from '../../api'
import { Button, Dialog, DialogContent, DialogTitle, LinearProgress, TextField } from '@mui/material'
import { DetailDialog } from '../../component/DetailDialog'

console.log(process.env.REACT_APP_API)

function DepositDialog(props: { onClose: (updated: boolean) => void; user: any; open: boolean }) {
  const { onClose, user, open } = props

  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>입금</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="금액"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            value={amount}
            onChange={(event) => {
              setAmount(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={async (e: any) => {
              console.log(amount)
              setLoading(true)
              await depositUserBalance({ userIdx: user.idx, amount: Number(amount) })
              setLoading(false)
              onClose(true)
            }}
          >
            입금
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
function WithdrawDialog(props: { onClose: (updated: boolean) => void; user: any; open: boolean }) {
  const { onClose, user, open } = props

  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>출금</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="금액"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            value={amount}
            onChange={(event) => {
              setAmount(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="error"
            disabled={loading}
            onClick={async (e: any) => {
              console.log(amount)
              setLoading(true)
              await withdrawUserBalance({ userIdx: user.idx, amount: Number(amount) })
              setLoading(false)
              onClose(true)
            }}
          >
            출금
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function UserListPage() {
  const [needUpdate, setNeedUpdate] = useState(false)
  const [userId, setUserId] = useState('')
  const [agentId, setAgentId] = useState('')
  const [master, setMaster] = useState('')
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = React.useState<any>({})
  const [openContentsDialog, setOpenContentsDialog] = React.useState(false)
  const [openDepositDialog, setOpenDepositDialog] = React.useState(false)
  const [openWithdrawDialog, setOpenWithdrawDialog] = React.useState(false)

  const logColumes: GridColumns = [
    {
      field: 'action',
      headerName: '상세',
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation() // don't select this row after clicking
          setSelectedUser(params.row)
          setOpenContentsDialog(true)
        }

        return <Button onClick={onClick}>상세</Button>
      },
    },

    {
      field: 'idx',
      headerName: 'Idx',
      width: 150,
    },
    {
      field: 'id',
      headerName: 'UserId',
      width: 150,
    },
    {
      field: 'agent',
      headerName: 'AgentId',
      width: 150,
    },
    {
      field: 'master',
      headerName: 'Master',
      width: 150,
    },
    {
      field: 'balance',
      headerName: '잔고',
      width: 150,
      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'regdate',
      headerName: '가입일자',
      width: 200,
      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'logindate',
      headerName: '로그인일자',
      width: 200,
      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'depositAction',
      headerName: '입출금',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              color="success"
              onClick={(e: any) => {
                e.stopPropagation() // don't select this row after clicking
                setSelectedUser(params.row)
                setOpenDepositDialog(true)
              }}
            >
              입금
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={(e: any) => {
                e.stopPropagation() // don't select this row after clicking
                setSelectedUser(params.row)
                setOpenWithdrawDialog(true)
              }}
            >
              출금
            </Button>
          </div>
        )
      },
    },
  ]

  function fetchCondtion() {
    return userId !== '' || agentId !== '' || master !== ''
  }

  async function fetchData() {
    if (fetchCondtion() === false) {
      return alert('검색조건 하나는 꼭 필요합니다.')
    }

    setLoading(true)

    const params = {
      ...(userId !== '' && { userId }),
      ...(master !== '' && { master }),
      ...(agentId !== '' && { agentId }),
    }

    try {
      const rawDatas = await getUsers(params)
      console.log(rawDatas)
      const datas = rawDatas.map((value) => {
        return { ...value, regdate: new Date(value.regdate), logindate: new Date(value.logindate) }
      })

      setUsers(datas)
      setLoading(false)
    } catch (err) {
      console.log(err)
      alert('fetch error')
    }
  }

  if (needUpdate) {
    if (fetchCondtion()) {
      fetchData()
    }
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    if (fetchCondtion()) {
      fetchData()
    }
    //fetchData({date:currentDate})
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0px', alignSelf: 'flex-start' }}>
        <TextField
          style={{ minWidth: '100px', margin: '0px 10px' }}
          id="outlined-basic"
          label="UserId"
          fullWidth
          value={userId}
          onChange={(evt) => {
            setUserId(evt.target.value)
          }}
        />
        <TextField
          style={{ minWidth: '100px', margin: '0px 10px' }}
          id="outlined-basic"
          label="AgentId"
          fullWidth
          value={agentId}
          onChange={(evt) => {
            setAgentId(evt.target.value)
          }}
        />
        <TextField
          style={{ minWidth: '100px', margin: '0px 10px' }}
          id="outlined-basic"
          label="master"
          fullWidth
          value={master}
          onChange={(evt) => {
            setMaster(evt.target.value)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
      </div>
      <DataGrid
        style={{ width: '100%', height: '100%' }}
        components={{ LoadingOverlay: LinearProgress }}
        getRowId={(row) => row.idx}
        rows={users}
        columns={logColumes}
        pageSize={100}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        loading={loading}
      />
      <DetailDialog
        open={openContentsDialog}
        content={JSON.stringify(selectedUser, null, 2)}
        onClose={(updated: boolean) => {
          setOpenContentsDialog(updated)
        }}
      />
      <DepositDialog
        open={openDepositDialog}
        user={selectedUser}
        onClose={(updated: boolean) => {
          setOpenDepositDialog(false)
          if (updated) setNeedUpdate(true)
        }}
      />
      <WithdrawDialog
        open={openWithdrawDialog}
        user={selectedUser}
        onClose={(updated: boolean) => {
          setOpenWithdrawDialog(false)
          if (updated) setNeedUpdate(true)
        }}
      />
    </div>
  )
}

export default UserListPage
