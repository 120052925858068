import { useState, useEffect, Fragment } from 'react'

import { format, parse } from 'date-fns'
import {
  Checkbox,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  FormControl,
  InputLabel,
} from '@mui/material'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { koKR } from '@mui/x-date-pickers/locales'

import { getAgents, getAgentVendorUsage } from '../../api'
import { Agent } from '../../api.interface'

console.log(process.env.REACT_APP_API)

const numberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

interface VendorUsageRow {
  vendorCode: string
  amountBet: number
  amountWin: number
  profit: number
}

interface CustomDailyStat {
  day: number
  dateTime: Date
  amountBet: number
  amountWin: number
  profit: number
  //vendorUsages: VendorUsageRow[]
  vendorUsages: { [code: string]: VendorUsageRow }
}

function DailyAgentVendorUsage() {
  const [dailyStats, setDailyStats] = useState<CustomDailyStat[]>([])
  const [agents, setAgents] = useState<Agent[]>([])
  const [totalVendorUsages, setTotalVendorUsages] = useState<VendorUsageRow[]>([])
  const [needUpdate, setNeedUpdate] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const agentIdParam = searchParams.get('agent')
  const includeSubParam = searchParams.get('includeSub') === 'true'
  const startDateParam = searchParams.get('startDate')
  const endDateParam = searchParams.get('endDate')

  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const currentYearMonth = params.get('month') ?? format(new Date(), 'yyyy-MM')

  console.log('DailyAgentVendorUsage ', currentYearMonth, agentIdParam, includeSubParam)

  async function fetchData() {
    try {
      if (startDateParam == null || endDateParam == null) {
        return
      }

      const rawData = await getAgentVendorUsage({
        ...(agentIdParam != null && { agentId: agentIdParam }),
        startDate: startDateParam,
        endDate: endDateParam,
        includeSub: includeSubParam,
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const agentVendorUsages = rawData.data

      const dailyStatDict: {
        [day: string]: CustomDailyStat
      } = {}

      const totalVendorUsageDict: {
        [code: string]: VendorUsageRow
      } = {}

      for (const agentVendorUsage of agentVendorUsages) {
        const dailyStat = (dailyStatDict[agentVendorUsage.day] ??= {
          day: agentVendorUsage.day,
          dateTime: new Date(agentVendorUsage.day),
          amountBet: 0,
          amountWin: 0,
          profit: 0,
          vendorUsages: {},
        })

        for (const [key, value] of Object.entries(agentVendorUsage.vendorUsages)) {
          const profit = value.amountBet - value.amountWin

          const vendorUsage = (dailyStat.vendorUsages[key] ??= {
            vendorCode: key,
            amountBet: 0,
            amountWin: 0,
            profit: 0,
          })

          const totalVendorUsage = (totalVendorUsageDict[key] ??= {
            vendorCode: key,
            amountBet: 0,
            amountWin: 0,
            profit: 0,
          })

          vendorUsage.amountBet += value.amountBet
          vendorUsage.amountWin += value.amountWin
          vendorUsage.profit += profit

          totalVendorUsage.amountBet += value.amountBet
          totalVendorUsage.amountWin += value.amountWin
          totalVendorUsage.profit += profit

          dailyStat.amountBet += value.amountBet
          dailyStat.amountWin += value.amountWin
          dailyStat.profit += profit
        }
      }

      const newDailyStats = Object.values(dailyStatDict).sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime())
      setDailyStats(newDailyStats)

      const newTotalVendorUsages = Object.values(totalVendorUsageDict).sort((a, b) => b.profit - a.profit)
      setTotalVendorUsages(newTotalVendorUsages)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }
  async function fetchAgents() {
    try {
      const rawData = await getAgents({})
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setAgents(rawData.data.filter((value) => value.status === 'reg'))
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
    fetchAgents()
  }, [])

  function VendorRow(props: { row: CustomDailyStat }) {
    const { row } = props
    const [open, setOpen] = useState(false)

    return (
      <Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="right">
            <div>{format(parse(row.day.toString(), 'yyyyMMdd', new Date()), 'yyyy-MM-dd')}</div>
          </TableCell>
          <TableCell align="right">
            <div>{numberFormat.format(row.amountBet ?? 0)}</div>
          </TableCell>
          <TableCell align="right">
            <div>{numberFormat.format(row.amountWin ?? 0)}</div>
          </TableCell>
          <TableCell align="right">
            <div>{numberFormat.format(row.profit ?? 0)}</div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>벤더코드</TableCell>
                      <TableCell align="right">베팅금</TableCell>
                      <TableCell align="right">승리금</TableCell>
                      <TableCell align="right">이익금</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(row.vendorUsages)
                      .sort((a, b) => b.profit - a.profit)
                      .map((vendorRow) => (
                        <TableRow key={vendorRow.vendorCode}>
                          <TableCell component="th" scope="row">
                            {vendorRow.vendorCode}
                          </TableCell>
                          <TableCell align="right">{numberFormat.format(vendorRow.amountBet)}</TableCell>
                          <TableCell align="right">{numberFormat.format(vendorRow.amountWin)}</TableCell>
                          <TableCell align="right">{numberFormat.format(vendorRow.profit)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }

  console.log('FakeDailyStatsPage render')

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          minWidth: 800,
          maxWidth: 1500,
          marginLeft: '20px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'left', marginTop: '20px' }}>
          <FormControl style={{ width: '150px' }}>
            <InputLabel id="demo-simple-select-label">에이전트</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={agentIdParam}
              label="Agent"
              onChange={(event) => {
                searchParams.set('agent', event.target.value as string)
                setSearchParams(searchParams)
                setNeedUpdate(true)
              }}
            >
              {agents.map((value) => (
                <MenuItem key={value.agentId} value={value.agentId}>
                  {`${value.agentId} (${value.nick})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            style={{ minWidth: '100px', margin: '0px 10px' }}
            control={
              <Checkbox
                value={includeSubParam}
                onChange={(event) => {
                  searchParams.set('includeSub', event.target.checked.toString())
                  setSearchParams(searchParams)
                  setNeedUpdate(true)
                }}
              />
            }
            label="하위 에이전트 포함"
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <DateRangePicker
            sx={{ maxWidth: '400px' }}
            value={[
              startDateParam != null ? parse(startDateParam, 'yyyyMMdd', new Date()) : null,
              endDateParam != null ? parse(endDateParam, 'yyyyMMdd', new Date()) : null,
            ]}
            onChange={(newValue) => {
              if (newValue[0] != null) {
                searchParams.set('startDate', format(newValue[0], 'yyyyMMdd'))
              }
              if (newValue[1] != null) {
                searchParams.set('endDate', format(newValue[1], 'yyyyMMdd'))
              }
              setSearchParams(searchParams)
              setNeedUpdate(true)
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Table sx={{ marginLeft: '10px', minWidth: '700px' }} stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">날짜</TableCell>
                <TableCell align="center">베팅머니</TableCell>
                <TableCell align="center">승리머니</TableCell>
                <TableCell align="center">수익</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dailyStats.map((row) => (
                <VendorRow key={row.day} row={row}></VendorRow>
              ))}
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">총합</TableCell>
                <TableCell align="right">
                  {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.amountBet ?? 0), 0))}
                </TableCell>
                <TableCell align="right">
                  {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.amountWin ?? 0), 0))}
                </TableCell>
                <TableCell align="right">
                  {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.profit ?? 0), 0))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Table sx={{ marginLeft: '10px', minWidth: '700px' }} stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">벤더</TableCell>
                <TableCell align="center">베팅머니</TableCell>
                <TableCell align="center">승리머니</TableCell>
                <TableCell align="center">수익</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totalVendorUsages.map((row) => (
                <TableRow>
                  <TableCell align="left">{row.vendorCode}</TableCell>
                  <TableCell align="right">{numberFormat.format(row.amountBet)}</TableCell>
                  <TableCell align="right">{numberFormat.format(row.amountWin)}</TableCell>
                  <TableCell align="right">{numberFormat.format(row.profit)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default DailyAgentVendorUsage
