import { useState, useEffect } from 'react'

import {
  deleteBlacklistUser,
  FakeBlacklistUser,
  getBlacklistUsers,
  getFakeSockets,
  registerBlacklistUser,
} from '../../api'
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableRow,
  TableBody,
  Typography,
  Button,
  Select,
  MenuItem,
} from '@mui/material'
import { DetailDialog } from '../../component/DetailDialog'
import { FakeSocketStat } from '../../api.interface'
import { useSearchParams } from 'react-router-dom'

console.log(process.env.REACT_APP_API)

interface FakeSocketUser {
  username: string
  connections: {
    username: string

    url: string

    type: string

    ip: string
    headers: { [key: string]: string }

    tableId: string
  }[]
}

const sockets = {
  blue: {
    name: '블루',
    cluster: 'fake-api-blue',
    serviceName: 'fake-api-blue-srv',
    region: 'ap-northeast-2',
  },
  green: {
    name: '그린',
    cluster: 'fake-api-green',
    serviceName: 'fake-api-green-srv',
    region: 'ap-northeast-2',
  },
  lemon: {
    name: '레몬',
    cluster: 'fake-api-lemon',
    serviceName: 'fake-api-lemon-srv',
    region: 'ap-northeast-2',
  },
  tomato: {
    name: '토마토',
    cluster: 'fake-api-tomato',
    serviceName: 'fake-api-tomato-srv',
    region: 'ap-northeast-2',
  },
  gold: {
    name: '골드',
    cluster: 'fake-api-gold',
    serviceName: 'fake-api-gold-srv',
    region: 'ap-northeast-2',
  },
  silver: {
    name: '실버',
    cluster: 'fake-api-silver',
    serviceName: 'fake-api-silver-srv',
    region: 'ap-northeast-2',
  },
} as {
  [key: string]: {
    name: string
    cluster: string
    serviceName: string
    region: string
  }
}

function FakeSocketPage() {
  const [socketServers, setSocketServers] = useState<FakeSocketStat[]>([])
  const [sockerUsers, setSocketUsers] = useState<FakeSocketUser[]>([])
  const [blacklistUsers, setBlacklistUsers] = useState<{ [username: string]: FakeBlacklistUser }>({})

  const [openDetailDialog, setOpenDetailDialog] = useState(false)
  const [selectedContent, setSelectedContent] = useState('')
  const [needUpdate, setNeedUpdate] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const socket = searchParams.get('socket') ?? ''
  console.log('FakeSocketPage')

  async function fetchBlacklistUsers() {
    try {
      const res = await getBlacklistUsers()
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      const blacklistUsers: { [username: string]: FakeBlacklistUser } = {}

      for (const user of res.data) {
        blacklistUsers[user.username] = {
          ...user,
          createdAt: new Date(user.createdAt),
        }
      }

      setBlacklistUsers(blacklistUsers)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  async function fetchData() {
    try {
      const socketData = sockets[socket]

      if (socketData == null) {
        return
      }

      const { cluster, serviceName, region } = socketData

      const res = await getFakeSockets({ cluster, serviceName, region })
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      const socketUsers: { [username: string]: FakeSocketUser } = {}
      for (const server of res.data) {
        server.createdAt = new Date(server.createdAt)
        for (const connection of server.connections ?? []) {
          const user = (socketUsers[connection.username] ??= {
            username: connection.username,
            connections: [],
          })
          user.connections.push(connection)
        }
      }

      setSocketServers(res.data)
      setSocketUsers(Object.values(socketUsers))
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    setNeedUpdate(false)
    fetchData()
  }

  useEffect(() => {
    console.log('FakeSocketPage useEffect')
    fetchBlacklistUsers()
    fetchData()
    return () => {
      console.log('FakeSocketPage useEffect2')
    }
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%' }}>
      <div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={socket}
          label="테이블"
          onChange={(event) => {
            searchParams.set('socket', event.target.value as string)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {Object.entries(sockets).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {`${value.name}`}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <div style={{ padding: '10px' }}>{`전체 유저수 : ${sockerUsers.length}`}</div>
        <Grid container>
          {sockerUsers.map((row) => (
            <Card sx={{ minWidth: 200 }} key={row.username}>
              <CardContent>
                <Typography variant="h6" component="div">
                  <span>{row.username}</span>
                  {blacklistUsers[row.username] == null ? (
                    <Button
                      variant="outlined"
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        registerBlacklistUser({
                          username: row.username,
                          ip: row?.connections[0]?.ip,
                          headers: row?.connections[0]?.headers,
                        }).then(() => {
                          fetchBlacklistUsers()
                        })
                      }}
                    >
                      블랙등록
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="error"
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        deleteBlacklistUser({
                          username: row.username,
                        }).then(() => {
                          fetchBlacklistUsers()
                        })
                      }}
                    >
                      블랙해제
                    </Button>
                  )}
                </Typography>
                <Table sx={{ border: 1, borderCollapse: 1 }}>
                  <TableBody>
                    {Object.values(row?.connections ?? []).map((connection, index) => (
                      <TableRow sx={{ border: 1 }} key={index}>
                        <td>{connection.type}</td>
                        <td>{connection.tableId}</td>
                        <td>{connection.ip}</td>
                        <td>
                          <Button
                            style={{ marginLeft: '10px' }}
                            variant="outlined"
                            onClick={() => {
                              setSelectedContent(JSON.stringify(connection.headers, null, 2))
                              setOpenDetailDialog(true)
                            }}
                          >
                            헤더보기
                          </Button>
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </div>
      <div>
        <div style={{ padding: '10px' }}>{`전체 연결수 : ${socketServers.reduce(
          (acc, cur) => acc + (cur?.connectionLength ?? 0),
          0,
        )}`}</div>
        <Grid container>
          {socketServers.map((row) => (
            <Card sx={{ minWidth: 200 }} key={row.id}>
              <CardContent>
                <Typography sx={{ fontSize: 16 }} component="div">
                  {row.id}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'상태 : ' + row.status}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'사용 메모리 : ' + (row.rss ?? '없음')}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'연결 수 : ' + (row.connectionLength ?? 0)}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'생성 시간 : ' + row.createdAt.toLocaleString()}
                </Typography>
                <Table sx={{ border: 1, borderCollapse: 1 }}>
                  <TableBody>
                    {Object.values(row?.connections ?? []).map((connection, index) => (
                      <TableRow sx={{ border: 1 }} key={index}>
                        <td>{connection.username}</td>
                        <td>{connection.type}</td>
                        <td>{connection.tableId}</td>
                        <td>{connection.ip}</td>
                        <td>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedContent(JSON.stringify(connection.headers, null, 2))
                              setOpenDetailDialog(true)
                            }}
                          >
                            헤더보기
                          </Button>
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </div>
      <DetailDialog
        open={openDetailDialog}
        content={selectedContent}
        onClose={(updated: boolean) => {
          setOpenDetailDialog(updated)
        }}
      />
    </div>
  )
}

export default FakeSocketPage
