import { useState, useEffect } from 'react'

import { getEvoRedirectInfo, postEvoSubRedirect, RedirectInfo } from '../../api'
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { useSearchParams } from 'react-router-dom'
import { redirectColorTable, redirectMenuItems } from '../../util/redirect-items'

console.log(process.env.REACT_APP_API)

const subRedirectMenuItems = [
  {
    key: '',
    name: '삭제',
  },
  ...redirectMenuItems,
]

//const redirectParamItems = ['honorlink-games.com', 'swix-games.net', 'union-games.org']

function FakeSubRedirectPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const agentIdParam = searchParams.get('agent')
  console.log('FakeSubRedirectPage : ' + agentIdParam)
  const [evoRedirects, setEvoRedirects] = useState<RedirectInfo[]>([])

  const [selectedRedirect, setSelectedRedirect] = useState<RedirectInfo>()

  const [redirectUrls, setRedirectUrls] = useState<string[]>([])

  async function fetchData() {
    try {
      const res = await getEvoRedirectInfo()
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      const { lists, redirectUrls } = res.data

      const datas = lists.filter((value) => ({
        ...value,
        // redirectParamItems에 있는거만 나오게 한다.
        redirectParam: redirectUrls.find((item) => item === value.redirectParam),
      }))

      setRedirectUrls(redirectUrls)
      setEvoRedirects(datas)

      const evoRedirect = datas.find((value) => value.agentId === agentIdParam)
      setSelectedRedirect(evoRedirect)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="Log">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={agentIdParam}
          label="Agent"
          onChange={(event) => {
            searchParams.set('agent', event.target.value as string)
            setSearchParams(searchParams)

            const evoRedirect = evoRedirects.find((value) => value.agentId === event.target.value)

            setSelectedRedirect(evoRedirect)
          }}
        >
          {evoRedirects.map((value) => (
            <MenuItem key={value.agentId} value={value.agentId}>
              {`${value.agentId} (${value.nick})`}
            </MenuItem>
          ))}
        </Select>
        <div style={{ marginLeft: '100px' }}></div>
      </div>
      <div style={{ marginTop: '10px' }}></div>
      <Table sx={{ maxWidth: 600, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>접속 도메인</TableCell>
            <TableCell align="center">리다이렉트 벤더</TableCell>
            <TableCell align="center">페이크 도메인</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(selectedRedirect?.entryDomainSettings ?? {}).map(([key, row], index) => {
            return (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell align="center">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={row.redirectTo ?? ''}
                    style={{ backgroundColor: redirectColorTable[row.redirectTo ?? ''] ?? 'white' }}
                    label="리디렉트"
                    onChange={async (event) => {
                      if (selectedRedirect == null) {
                        return
                      }
                      const redirectTo = event.target.value

                      const res = await postEvoSubRedirect({
                        agentCode: selectedRedirect.agentCode,
                        entryDomain: key,
                        redirectTo: event.target.value,
                      })
                      if (res.status === 0) {
                        const redirect = selectedRedirect.entryDomainSettings?.[key]
                        if (redirect != null) {
                          redirect.redirectTo = redirectTo
                          setSelectedRedirect({ ...selectedRedirect })
                        }
                      }
                    }}
                  >
                    {subRedirectMenuItems.map(({ key, name }) => (
                      <MenuItem id={key} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell align="center">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={row.redirectDomain ?? ''}
                    label="리디렉트"
                    onChange={async (event) => {
                      if (selectedRedirect == null) {
                        return
                      }

                      const redirectDomain = event.target.value === '삭제' ? '' : event.target.value

                      const res = await postEvoSubRedirect({
                        agentCode: selectedRedirect.agentCode,
                        entryDomain: key,
                        redirectDomain,
                      })
                      if (res.status === 0) {
                        const redirect = selectedRedirect.entryDomainSettings?.[key]
                        if (redirect != null) {
                          redirect.redirectDomain = redirectDomain
                          setSelectedRedirect({ ...selectedRedirect })
                        }
                      }
                    }}
                  >
                    {['삭제', ...redirectUrls].map((value) => (
                      <MenuItem id={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeSubRedirectPage
