import { useState, useEffect } from 'react'

import { getEvoRedirectInfo, postEvoRedirect, postGroupEvoRedirect, setEvoRedirectGroup } from '../../api'
import {
  FormControl,
  InputLabel,
  Switch,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import { VendorCode } from '../../util/types'
import { redirectColorTable, redirectMenuItems } from '../../util/redirect-items'

console.log(process.env.REACT_APP_API)

//const redirectParamItems = ['honorlink-games.com', 'swix-games.net', 'union-games.org']

function EvoRedirectPage() {
  const [evoRedirects, setEvoRedirects] = useState<
    {
      idx: number
      agentCode: string
      agentId: string
      group?: boolean
      nick: string
      redirectTo: string
      fakeVendor?: string
      redirectParam?: string
    }[]
  >([])

  const [redirectUrls, setRedirectUrls] = useState<string[]>([])

  async function fetchData() {
    try {
      const res = await getEvoRedirectInfo()
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      const { lists, redirectUrls } = res.data

      const datas = lists.filter((value) => ({
        ...value,
        // redirectParamItems에 있는거만 나오게 한다.
        redirectParam: redirectUrls.find((item) => item === value.redirectParam),
      }))

      setRedirectUrls(redirectUrls)
      setEvoRedirects(datas)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="Log">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">그룹 리디렉트</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={'그룹 리디렉트'}
            label="그룹 리디렉트"
            onChange={async (event) => {
              if (
                event.target.value === VendorCode.FakeBlue_Evolution ||
                event.target.value === VendorCode.FakeGreen_Evolution ||
                event.target.value === VendorCode.FakeLemon_Evolution
              ) {
                for (const i of evoRedirects) {
                  if (i.group && (i.redirectParam == null || i.redirectParam === '')) {
                    return alert(`그룹에 에이전트 ${i.agentId} 가 리다이렉트 주소가 있습니다.`)
                  }
                }
              }

              const res = await postGroupEvoRedirect({ redirectTo: event.target.value })
              if (res.status === 0) {
                fetchData()
              }
            }}
          >
            {redirectMenuItems.map(({ key, name }) => (
              <MenuItem id={key} value={key}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ marginLeft: '100px' }}></div>
      </div>
      <div style={{ marginTop: '10px' }}></div>
      <Table sx={{ maxWidth: 600, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Idx</TableCell>
            <TableCell align="center">Code</TableCell>
            <TableCell align="center">AgentCode</TableCell>
            <TableCell align="center">Nick</TableCell>
            <TableCell align="center">그룹</TableCell>
            <TableCell align="center">리다이렉트 벤더</TableCell>
            <TableCell align="center">페이크 주소</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {evoRedirects.map((row, index) => {
            return (
              <TableRow key={row.idx}>
                <TableCell>{row.idx}</TableCell>
                <TableCell align="left">{row.agentCode}</TableCell>
                <TableCell align="left">{row.agentId}</TableCell>
                <TableCell align="left">{row.nick}</TableCell>
                <TableCell align="left">
                  <Switch
                    checked={row.group === true}
                    onChange={async (event) => {
                      const res = await setEvoRedirectGroup({
                        agentCode: row.agentCode.toString(),
                        group: row.group === true ? false : true,
                      })
                      if (res.status === 0) {
                        const evoRedirect = evoRedirects.find((value) => value.agentCode === row.agentCode.toString())
                        if (evoRedirect != null) {
                          evoRedirect.group = row.group === true ? false : true
                          setEvoRedirects([...evoRedirects])
                        }
                      }
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={row.redirectTo ?? ''}
                    style={{ backgroundColor: redirectColorTable[row.redirectTo] ?? 'white' }}
                    label="리디렉트"
                    onChange={async (event) => {
                      /*if (
                        (event.target.value === VendorCode.FakeBlue_Evolution ||
                          event.target.value === VendorCode.FakeGreen_Evolution ||
                          event.target.value === VendorCode.FakeLemon_Evolution) &&
                        (row.redirectParam == null || row.redirectParam === '')
                      ) {
                        return alert('페이크 설정시에는 리다이렉트 주소를 먼저 설정해 주세요.')
                      }*/

                      const res = await postEvoRedirect({ agentCode: row.agentCode, redirectTo: event.target.value })
                      if (res.status === 0) {
                        const redirect = evoRedirects.find((value) => value.idx === row.idx)
                        if (redirect != null) {
                          redirect.redirectTo = event.target.value
                          setEvoRedirects([...evoRedirects])
                        }
                      }
                    }}
                  >
                    {redirectMenuItems.map(({ key, name }) => (
                      <MenuItem id={key} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell align="center">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={row.redirectParam ?? ''}
                    label="리디렉트"
                    onChange={async (event) => {
                      const res = await postEvoRedirect({ agentCode: row.agentCode, redirectParam: event.target.value })
                      if (res.status === 0) {
                        const redirect = evoRedirects.find((value) => value.idx === row.idx)
                        if (redirect != null) {
                          redirect.redirectParam = event.target.value
                          setEvoRedirects([...evoRedirects])
                        }
                      }
                    }}
                  >
                    {redirectUrls.map((value) => (
                      <MenuItem id={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default EvoRedirectPage
