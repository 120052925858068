import React, { useState, useEffect } from 'react'

import { getCasinoBet, getSlotBet } from '../api'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import { BettingItem } from '../api.interface'

interface DetailEvent {
  time: Date
  event: string
  content: string
}

export function BetDetailDialog(props: {
  onClose: (updated: boolean) => void
  betId: string
  gameType: string
  open: boolean
}) {
  const { onClose, betId, gameType, open } = props

  const [bet, setBet] = useState<BettingItem>()
  const [events, setEvents] = useState<DetailEvent[]>([])

  const handleClose = () => {
    onClose(false)
  }

  const borderColor = 'lightgrey'

  const styles = {
    padding: '10px',
    margin: 0,
    borderRadius: '0 0 2px 2px',
    borderTop: 'solid 1px ' + borderColor,
  }

  const updateDetail = async () => {
    try {
      if (betId == null || betId == null) {
        return
      }

      const rawData = await (gameType === 'casino'
        ? getCasinoBet({ objectId: betId })
        : getSlotBet({ objectId: betId }))
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      setBet(rawDatas)

      setEvents(events)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    // open으로 열렸을 때만 데이터를 가져온다.
    if (open) {
      updateDetail()
    }
  }, [open])

  return (
    <Dialog
      onClose={() => {
        onClose(false)
      }}
      open={open}
      maxWidth="xl"
    >
      <DialogTitle>상세</DialogTitle>
      <DialogContent>
        <pre
          style={{
            padding: '10px',
            margin: 0,
            borderRadius: '0 0 2px 2px',
            borderTop: 'solid 1px ' + borderColor,
          }}
        >
          <code>{JSON.stringify(bet, null, 2)}</code>
        </pre>
      </DialogContent>
    </Dialog>
  )
}
