import { useState, useEffect } from 'react'

import {
  FakeDomain,
  deleteFakeUrl,
  getFakeDomainDetail,
  getFakeDomains,
  postEvoDomainRedirect,
  postFakeDomainCert,
  postFakeDomainHostedZoneRecord,
  postFakeDomainSubDomain,
  postFakeUrl,
} from '../../api'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
} from '@mui/material'

import { sleep } from '../../util'
import { redirectColorTable, redirectMenuItems } from '../../util/redirect-items'

console.log(process.env.REACT_APP_API)

const subDomains = [
  {
    name: 'babylonagst3',
    apiId: 'y2xiyl3z1i',
    stage: 'blue',
  },
  { name: 'babylongr1', apiId: 'h8ad2l6bx5', stage: 'green' },
  {
    name: 'babylonkrst2',
    apiId: 'u1ukl33cdl',
    stage: 'lemon',
  },
  {
    name: 'babylond4',
    apiId: 'tojkyiwca6',
    stage: 'tomato',
  },
  {
    name: 'babylongld5',
    apiId: 'drtmljou8b',
    stage: 'gold',
  },
  {
    name: 'babylondslv6',
    apiId: 'grsltrkcdb',
    stage: 'silver',
  },
  /*{
    name: 'skylinemtgrap7',
    apiId: 'y7e4ouhvg7',
    stage: 'grape',
  },
  {
    name: 'skylinemtgapp8',
    apiId: 'egeqgex3wl',
    stage: 'apple',
  },*/
]

function toCertStatusText(status: string) {
  switch (status) {
    case 'ISSUED':
      return '발급됨'
    case 'PENDING_VALIDATION':
      return '검증 대기중'
  }

  return status
}

interface FakeDomainDetail extends FakeDomain {
  validationRecord?: {
    Name: string
    Value: string
  }
  subDomainNames?: {
    DomainName: string
    /**
     * The domain name configurations.
     */
    DomainNameConfigurations?: {
      ApiGatewayDomainName: string
      DomainNameStatus: string
    }[]

    Items: {
      ApiId: string
      ApiMappingId: string
      Stage: string
    }[]
  }[]
}

function FakeDomainDetailDialog(props: {
  selectedDomain?: FakeDomainDetail
  onUpdate: (updated: FakeDomainDetail | undefined) => void
}) {
  const { selectedDomain, onUpdate } = props
  const [registerCertClicked, setRegisterCertClicked] = useState(false)

  const [subDomainClicked, setSubDomainClicked] = useState(false)

  return (
    <Dialog
      onClose={() => {
        onUpdate(undefined)
      }}
      open={selectedDomain != null}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>페이크 도메인 {selectedDomain?.domainName} 상세</DialogTitle>
      <DialogContent>
        <div>
          <Button
            variant="contained"
            disabled={registerCertClicked || selectedDomain?.validationRecord != null}
            onClick={async () => {
              if (selectedDomain?.domainName == null) {
                return
              }
              setRegisterCertClicked(true)
              try {
                const certRes = await postFakeDomainCert(selectedDomain?.domainName)
                onUpdate({
                  ...selectedDomain,
                  cert: certRes.data,
                  validationRecord: certRes.data?.DomainValidationOptions[0].ResourceRecord,
                })
              } catch (err) {}

              setRegisterCertClicked(false)
            }}
          >
            인증서 등록
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            variant="contained"
            disabled={subDomainClicked}
            onClick={async () => {
              if (selectedDomain?.domainName == null || selectedDomain.cert?.Status !== 'ISSUED') {
                return
              }
              setSubDomainClicked(true)
              try {
                const newSubDomainNames = selectedDomain.subDomainNames ?? []
                for (const subDomainObj of subDomains) {
                  const fullDomain = subDomainObj.name + '.' + selectedDomain.domainName

                  const subDomain = selectedDomain.subDomainNames?.find((value) => value.DomainName === fullDomain)

                  if (subDomain == null) {
                    const subDomainRes = await postFakeDomainSubDomain({
                      fullDomain,
                      certArn: selectedDomain.cert.CertificateArn,
                      apiId: subDomainObj.apiId,
                      stage: subDomainObj.stage,
                    })
                    const recordValue = subDomainRes.data.DomainNameConfigurations?.[0].ApiGatewayDomainName
                    if (selectedDomain.hostedZone != null && recordValue != null) {
                      console.log('register hostedZoneRecord')

                      const zoneId = selectedDomain.hostedZone.Id.split('/')[2]
                      const hostedZoneRes = await postFakeDomainHostedZoneRecord({
                        domainName: fullDomain,
                        hostedZoneId: zoneId,
                        recordValue,
                      })
                      console.log(hostedZoneRes)
                    }
                    newSubDomainNames.push(subDomainRes.data)
                    onUpdate({
                      ...selectedDomain,
                      subDomainNames: newSubDomainNames,
                    })
                    await sleep(60_000)
                  } else {
                    newSubDomainNames.push(subDomain)
                    onUpdate({
                      ...selectedDomain,
                      subDomainNames: newSubDomainNames,
                    })
                  }
                }
              } catch (err) {
                console.log(err)
              }

              setSubDomainClicked(false)
            }}
          >
            서브 도메인 등록
          </Button>
          <Table sx={{ maxWidth: 'md' }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>인증서 상태</TableCell>
                <TableCell>{toCertStatusText(selectedDomain?.cert?.Status ?? '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>아마존 도메인 상태</TableCell>
                <TableCell>{toCertStatusText(selectedDomain?.hostedZone != null ? '등록' : '해제')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CNAME 이름</TableCell>
                <TableCell>{selectedDomain?.validationRecord?.Name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CNAME 값</TableCell>
                <TableCell>{selectedDomain?.validationRecord?.Value}</TableCell>
              </TableRow>
              {selectedDomain?.subDomainNames?.map((value) => (
                <TableRow>
                  <TableCell>{value?.DomainName}</TableCell>
                  <TableCell>{value?.DomainNameConfigurations?.[0].ApiGatewayDomainName}</TableCell>
                  <TableCell>{value?.Items?.[0].Stage}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function FakeDomainPage() {
  const [newFakeUrl, setNewFakeUrl] = useState('')

  const [fakeDomains, setFakeDomains] = useState<FakeDomain[]>([])

  const [selectedDomain, setSelectedDomain] = useState<FakeDomainDetail | undefined>()

  async function fetchData() {
    try {
      const res = await getFakeDomains()
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      setFakeDomains(res.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="Log">
      <FakeDomainDetailDialog
        selectedDomain={selectedDomain}
        onUpdate={(updated) => {
          setSelectedDomain(updated)
        }}
      ></FakeDomainDetailDialog>
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <TextField
          label="새 주소"
          fullWidth
          onChange={(event) => setNewFakeUrl(event.target.value)}
          value={newFakeUrl}
        />
        <Button
          variant="contained"
          style={{
            marginLeft: '10px',
          }}
          onClick={async () => {
            if (newFakeUrl === '') {
              return alert('주소를 입력하세요.')
            }

            if (fakeDomains.find((value) => value.domainName === newFakeUrl) != null) {
              return alert('이미 존재하는 주소 입니다.')
            }

            await postFakeUrl({ domainName: newFakeUrl })

            fakeDomains.push({ domainName: newFakeUrl })

            setFakeDomains([...fakeDomains])
            setNewFakeUrl('')
          }}
        >
          추가
        </Button>
      </div>
      <Table sx={{ maxWidth: 'sm' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>도메인</TableCell>
            <TableCell>인증서 상태</TableCell>
            <TableCell>상세</TableCell>
            <TableCell>제거</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fakeDomains.map((value) => {
            return (
              <TableRow key={value.domainName}>
                <TableCell>{value.domainName}</TableCell>
                <TableCell align="center">{toCertStatusText(value.cert?.Status ?? '')}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    onClick={async () => {
                      if (value.cert?.CertificateArn != null) {
                        const fakeDomain = await getFakeDomainDetail(value.cert?.CertificateArn)
                        setSelectedDomain({ ...fakeDomain.data, ...value })
                      } else {
                        setSelectedDomain(value)
                      }
                    }}
                  >
                    상세
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={async () => {
                      await deleteFakeUrl({ domainName: value.domainName })

                      setFakeDomains({ ...fakeDomains.filter((e) => e !== value) })
                    }}
                  >
                    제거
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeDomainPage
