import { useState, useEffect } from 'react'

import { getVendors, setVendorUsed, updateGameListApi, updateGameListExcel } from '../../api'
import { Button, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { getFileBase64 } from '../../util'
import { VendorItem } from '../../api.interface'

console.log(process.env.REACT_APP_API)

function VendorListPage() {
  const [needUpdate, setNeedUpdate] = useState(false)
  const [vendors, setVendors] = useState<VendorItem[]>([])

  async function fetchVendors() {
    try {
      const rawData = await getVendors()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setVendors(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchVendors()
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', minWidth: '600px' }}>
      <Table sx={{ marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Code</TableCell>
            <TableCell align="center">영문 이름</TableCell>
            <TableCell align="center">한글 이름</TableCell>
            <TableCell align="center">관리 이름</TableCell>
            <TableCell align="center">베팅</TableCell>
            <TableCell align="center">엑셀 업데이트</TableCell>
            <TableCell align="center">API 업데이트</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vendors.map((row, index) => (
            <TableRow key={row._id}>
              <TableCell align="left">{row.code}</TableCell>
              <TableCell align="left">{row.nameEn}</TableCell>
              <TableCell align="left">{row.nameKo}</TableCell>
              <TableCell align="left">{row.nameManage}</TableCell>
              <TableCell align="center">
                <Switch
                  checked={row.used !== 'n'}
                  onChange={async (event) => {
                    const res = await setVendorUsed(row._id.toString(), row.used !== 'n' ? false : true)
                    if (res.status === 0) {
                      const game = vendors.find((value) => value._id === row._id.toString())
                      if (game != null) {
                        game.used = row.used !== 'n' ? 'n' : 'y'
                        setVendors([...vendors])
                      }
                    }
                  }}
                />
              </TableCell>
              <TableCell align="center">
                {row.gameType === 'slot' && (
                  <Button variant="contained" component="label" color="primary">
                    엑셀 업데이트
                    <input
                      hidden
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={async (event) => {
                        console.log(row)
                        console.log(event)
                        const files = event.target.files

                        if (files == null || files.length === 0) return

                        const excel = await getFileBase64(files[0])
                        updateGameListExcel(row.code, excel)

                        event.target.value = ''
                      }}
                    />
                  </Button>
                )}
              </TableCell>
              <TableCell align="center">
                {row.gameType === 'slot' && (
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={() => {
                      updateGameListApi(row.code)
                    }}
                  >
                    API 업데이트
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default VendorListPage
