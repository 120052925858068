import React, { useState, useEffect } from 'react'

import { getFakeChatLogs } from '../../api'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DetailDialog } from '../../component/DetailDialog'
import { FakeChatLog } from '../../api.interface'

console.log(process.env.REACT_APP_API)

const typeTable = {
  send: '보냄',
  receive: '시스템',
} as { [key: string]: string }

function FakeChatLogPage() {
  const [logs, setLogs] = useState<FakeChatLog[]>([])

  async function fetchData() {
    try {
      const rawData = await getFakeChatLogs()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const data = rawDatas.map((value) => ({
        ...value,
        chatTime: new Date(value.chatTime),
      }))

      setLogs(data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
  }, [])

  const borderColor = 'lightgrey'

  const styles = {
    padding: '10px',
    margin: 0,
    borderRadius: '0 0 2px 2px',
    borderTop: 'solid 1px ' + borderColor,
  }

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1200px' }}
    >
      <Table sx={{ maxWidth: 1000, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">시간</TableCell>
            <TableCell align="center">유저이름</TableCell>
            <TableCell align="center" width="50px">
              타입
            </TableCell>
            <TableCell align="center">테이블 id</TableCell>
            <TableCell align="center">채팅</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((row, index) => (
            <TableRow key={row._id}>
              <TableCell align="center">{row.chatTime.toLocaleString()}</TableCell>
              <TableCell align="center">{row.username?.toString()}</TableCell>
              <TableCell align="center">{typeTable[row.type]}</TableCell>
              <TableCell align="center">{row.tableId.toString()}</TableCell>
              <TableCell align="center">{row.chat}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeChatLogPage
