import { useState, useEffect } from 'react'

import { getAgentGameSetting, getAgents, getGames, getVendors, setAgentGameAllowBet } from '../../api'
import { MenuItem, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { Agent, BetSettingConfig, GameItem, VendorItem } from '../../api.interface'

console.log(process.env.REACT_APP_API)

function AgentGameSettingPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const agentIdParam = searchParams.get('agent')
  const vendorParam = searchParams.get('vendor')
  console.log(`AgentGameSettingPage ${agentIdParam} ${vendorParam}`)

  const [needUpdate, setNeedUpdate] = useState(false)
  const [vendorGameSetting, setVendorGameSetting] = useState<{
    [gameId: string]: BetSettingConfig
  }>({})
  const [games, setGames] = useState<GameItem[]>([])
  const [agents, setAgents] = useState<Agent[]>([])
  const [vendors, setVendors] = useState<VendorItem[]>([])

  function fetchCondtion() {
    return agentIdParam !== '' && vendorParam !== ''
  }

  async function fetchData() {
    if (fetchCondtion() === false) {
      return
    }

    if (agentIdParam == null || vendorParam == null) {
      return
    }

    try {
      const [settingRes, gameRes] = await Promise.all([
        getAgentGameSetting({ agentId: agentIdParam }),
        getGames(vendorParam),
      ])
      console.log(settingRes)
      console.log(gameRes)

      if (settingRes.data == null) {
        setVendorGameSetting({})
      } else {
        setVendorGameSetting(settingRes.data?.mergedVendorSettings?.[vendorParam]?.gameSettings ?? {})
      }

      if (gameRes.data == null) {
        throw 'error packet'
      }
      setGames(gameRes.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  async function fetchAgents() {
    try {
      const rawData = await getAgents({})
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setAgents(rawData.data.filter((value) => value.status === 'reg'))
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }
  async function fetchVendors() {
    try {
      const rawData = await getVendors()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setVendors(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    if (fetchCondtion()) {
      fetchData()
    }
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    if (fetchCondtion()) {
      fetchData()
    }
    fetchAgents()
    fetchVendors()
  }, [])

  return (
    <div
      className="Page"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1300px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={agentIdParam}
          label="Agent"
          onChange={(event) => {
            searchParams.set('agent', event.target.value as string)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {agents.map((value) => (
            <MenuItem key={value.agentId} value={value.agentId}>
              {`${value.agentId} (${value.nick})`}
            </MenuItem>
          ))}
        </Select>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vendorParam}
          label="Vendor"
          onChange={(event) => {
            searchParams.set('vendor', event.target.value as string)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {vendors.map((value) => (
            <MenuItem key={value.code} value={value.code}>
              {`${value.nameKo} (${value.code})`}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Table sx={{ maxWidth: 800, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>순서</TableCell>
            <TableCell align="center">게임 코드</TableCell>
            <TableCell align="center">영문 이름</TableCell>
            <TableCell align="center">한글 이름</TableCell>
            <TableCell align="center">전체 사용 여부</TableCell>
            <TableCell align="center">에이전트 사용 여부</TableCell>
            <TableCell align="center">이미지</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games.map((row, index) => (
            <TableRow key={row._id}>
              <TableCell align="center">{row.order}</TableCell>
              <TableCell align="left">{row.code}</TableCell>
              <TableCell align="left">{row.nameEn}</TableCell>
              <TableCell align="left">{row.nameKo}</TableCell>
              <TableCell align="center">
                <Switch disabled={true} checked={row.used === 'y'} />
              </TableCell>
              <TableCell align="center">
                <Switch
                  checked={vendorGameSetting[row.code]?.allowBet !== false}
                  onChange={async (event) => {
                    if (agentIdParam == null || vendorParam == null) return

                    const changeUsed = vendorGameSetting[row.code]?.allowBet === false ? true : false
                    const res = await setAgentGameAllowBet({
                      agentId: agentIdParam,
                      vendor: vendorParam,
                      gameId: row.code,
                      allowBet: changeUsed,
                    })
                    if (res.status === 0) {
                      const gameSetting = (vendorGameSetting[row.code] ??= {})
                      gameSetting.allowBet = changeUsed
                      setVendorGameSetting({
                        ...vendorGameSetting,
                      })
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                  }}
                >
                  <label htmlFor={row._id.toString() ?? ''}>
                    <img width="200" height="200" src={row.imgUrl} />
                  </label>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AgentGameSettingPage
