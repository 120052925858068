import { useState, useEffect } from 'react'

import { getEvoTables } from '../../api'
import { Card, CardContent, Grid, Table, TableRow, Typography } from '@mui/material'
import { MINUTE_MS } from '../../util'
import { EvoTable } from '../../api.interface'

console.log(process.env.REACT_APP_API)

interface EvoTableData extends Omit<EvoTable, 'updatedVendor'> {
  diffMs: number
  updatedVendor: {
    [key: string]: {
      time: Date
      diffMs: number
    }
  }
}

function EvoTablePage() {
  const [evoTables, setEvoTables] = useState<EvoTableData[]>([])

  async function fetchData() {
    try {
      const res = await getEvoTables()
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      const now = new Date()

      const datas = res.data.map((value) => {
        const updatedVendor: {
          [key: string]: {
            time: Date
            diffMs: number
          }
        } = {}

        for (const [key, time] of Object.entries(value.updatedVendor)) {
          updatedVendor[key] = {
            time: new Date(time),
            diffMs: now.getTime() - new Date(time).getTime(),
          }
        }

        return {
          ...value,
          updatedAt: new Date(value.updatedAt),
          eventTime: new Date(value.eventTime),
          diffMs: now.getTime() - new Date(value.eventTime).getTime(),
          settlementTime: new Date(value.settlementTime),
          updatedVendor: updatedVendor,
        }
      })

      setEvoTables(datas)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const now = new Date()

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Grid container spacing={2}>
        {evoTables.map((row) => (
          <Card
            sx={{ minWidth: 350, backgroundColor: row.diffMs > MINUTE_MS * 3 ? 'salmon' : 'white' }}
            key={row.tableId}
          >
            <CardContent>
              <Typography variant="h6" component="div">
                {row.tableName}
              </Typography>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                {row.tableId}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {'베팅 : ' + row.betting} <br />
                {'딜링 : ' + row.dealing}
              </Typography>
              <Typography variant="body2">
                {'현재 시간 : ' + now.toLocaleString()}
                <br />
                {'마지막 업데이트 시간 : ' + row.updatedAt.toLocaleString()}
                <br />
                {'벤더별 업데이트 지연 시간'}
                <Table sx={{ border: 1, borderCollapse: 1 }}>
                  {Object.entries(row.updatedVendor).map(([key, updatedVendor]) => (
                    <TableRow
                      sx={{ backgroundColor: updatedVendor.diffMs > MINUTE_MS * 3 ? 'salmon' : 'white', border: 1 }}
                    >
                      <td>{key}</td>
                      <td>{(updatedVendor.diffMs / 1000).toLocaleString() + ' 초'}</td>
                    </TableRow>
                  ))}
                </Table>
                <br />
                {'마지막 크롤링 처리 시간 : ' +
                  ((row.updatedAt.getTime() - row.eventTime.getTime()) / 1000).toLocaleString() +
                  ' 초'}
                <br />
                {'마지막 정산 처리 시간 : ' + ((row.settlementElapsedMs ?? 0) / 1000).toLocaleString() + ' 초'}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </div>
  )
}

export default EvoTablePage
