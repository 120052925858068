import { useState, useEffect } from 'react'

import { getAgentLoginLogs } from '../../api'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { AgentLoginLog } from '../../api.interface'

function AgentLoginLogPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const agentId = searchParams.get('agentId')
  console.log('LogAgentLoginPage : ' + agentId)

  const [textAgentId, setTextAgentId] = useState(agentId ?? '')

  const [needUpdate, setNeedUpdate] = useState(false)
  const [transactions, setTransactions] = useState<AgentLoginLog[]>([])

  async function fetchData() {
    const params = {
      agentId,
    }

    try {
      const rawData = await getAgentLoginLogs(params)
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const datas = rawDatas.map((value, idx) => {
        return { ...value, idx: idx + 1, timestamp: new Date(value.timestamp) }
      })

      setTransactions(datas)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
    //fetchData({date:currentDate})
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', maxWidth: '1200px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          width: '100%',
        }}
      >
        <TextField
          style={{ maxWidth: '150px', margin: '0px 10px' }}
          id="outlined-basic"
          label="agentId"
          fullWidth
          value={textAgentId}
          onChange={(evt) => {
            setTextAgentId(evt.target.value as string)
            //searchParams.set('username', evt.target.value as string)
            //setSearchParams(searchParams)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            if (textAgentId !== '' && textAgentId != null) {
              searchParams.set('agentId', textAgentId ?? '')
            } else {
              searchParams.delete('agentId')
            }
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
      </div>
      <Table sx={{ minWidth: 800, maxWidth: 1200, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">시간</TableCell>
            <TableCell align="center" width="30px">
              에이전트 Id
            </TableCell>
            <TableCell align="center">상태</TableCell>
            <TableCell align="center">메시지</TableCell>
            <TableCell align="center">Ip</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{row.timestamp?.toLocaleString()}</TableCell>
              <TableCell align="left">{row.agentId}</TableCell>
              <TableCell align="center">{row.status ?? ''}</TableCell>
              <TableCell align="center">{row.ms ?? ''}</TableCell>
              <TableCell align="center">{row.ip ?? ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AgentLoginLogPage
