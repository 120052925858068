import { createContext, useContext, useState } from 'react'
import cookie from 'react-cookies'

import './Login.css'
import { Button, TextField } from '@mui/material'
import { masterLogin } from './api'

console.log(process.env.REACT_APP_API)

export interface Session {
  session?: string
  setSession: (session: string) => void
}

export const SessionContext = createContext<Session>({
  session: undefined,
  setSession: () => {},
})

export function getSession() {
  return cookie.load('authorization')
}

function Login() {
  const { setSession } = useContext(SessionContext)

  const [masterId, setMasterId] = useState('')
  const [masterPw, setMasterPw] = useState('')

  async function doLogin() {
    try {
      const rawDatas = await masterLogin({ account: masterId, password: masterPw })

      if (rawDatas.status === 0) {
        cookie.save('authorization', rawDatas.data.session, { path: '/' })
        setSession(rawDatas.data.session)
      } else {
        alert('로그인 실패')
      }
    } catch (err) {
      console.log(err)
      alert('fetch error')
    }
  }

  return (
    <div className="App" style={{ display: 'flex' }}>
      <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0px', alignSelf: 'flex-start' }}>
          <TextField
            style={{ minWidth: '100px', margin: '0px 10px' }}
            id="outlined-basic"
            label="account"
            fullWidth
            value={masterId}
            onChange={(evt) => {
              setMasterId(evt.target.value)
            }}
          />
          <TextField
            style={{ minWidth: '100px', margin: '0px 10px' }}
            id="outlined-basic"
            label="password"
            fullWidth
            value={masterPw}
            onChange={(evt) => {
              setMasterPw(evt.target.value)
            }}
          />

          <Button style={{ minWidth: '100px' }} variant="contained" onClick={() => doLogin()}>
            {'확인'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
