import { useState, useEffect } from 'react'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'

import { getGames, getVendors, uploadGameImage, modifyGame, gamesMoveToTop, gamesMoveToBottom } from '../../api'
import { getFileImage, MINIMUM_IMAGE_SIZE } from '../../util'
import { useSearchParams } from 'react-router-dom'
import { GameItem, VendorItem } from '../../api.interface'

console.log(process.env.REACT_APP_API)

function ChangeNameDialog(props: {
  onClose: (setObj?: Partial<GameItem>) => void
  gameItem?: GameItem
  open: boolean
}) {
  const { onClose, gameItem, open } = props

  const [loading, setLoading] = useState(false)
  const [newText, setNewText] = useState<string>('')

  useEffect(() => {
    // open으로 열렸을 때만 초기화한다.
    if (open) {
      setNewText(gameItem?.nameKo ?? '')
    }
  }, [open])

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>이름 변경</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="이름"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            value={newText}
            onChange={(event) => {
              setNewText(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={async (e: any) => {
              console.log(newText)
              if (gameItem == null) {
                return
              }
              setLoading(true)
              const setObj = {
                nameKo: newText,
              }
              await modifyGame({
                _id: gameItem._id.toString(),
                setObj,
              })

              setLoading(false)
              onClose(setObj)
            }}
          >
            변경
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function GameListPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const vendor = searchParams.get('vendor')
  console.log('gameListPage : ' + vendor)
  const [needUpdate, setNeedUpdate] = useState(false)
  const [openChangeNameDialog, setOpenChangeNameDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState<GameItem>()
  //const [selectedVendor, setSelectedVendor] = useState('')
  const [vendors, setVendors] = useState<VendorItem[]>([])
  const [games, setGames] = useState<GameItem[]>([])

  async function changeImage(file: File, id: string) {
    const image = await getFileImage(file)

    // 이미지를 webp로 변환
    const canvas = document.createElement('canvas')

    canvas.width = image.width
    canvas.height = image.height

    //이미지가 너무 크면 적당히 리사이징해서 webp로 저장한다.
    if (canvas.width >= canvas.height) {
      if (canvas.width > MINIMUM_IMAGE_SIZE.width) {
        canvas.height = Math.round((MINIMUM_IMAGE_SIZE.width / canvas.width) * canvas.height)
        canvas.width = MINIMUM_IMAGE_SIZE.width
      }
    } else {
      if (canvas.height > MINIMUM_IMAGE_SIZE.height) {
        canvas.width = Math.round((MINIMUM_IMAGE_SIZE.height / canvas.height) * canvas.width)
        canvas.height = MINIMUM_IMAGE_SIZE.height
      }
    }

    const ctx = canvas.getContext('2d')
    if (ctx == null) {
      return
    }
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height) // draw the image

    console.log('save image')
    // do some manipulations...
    const base64 = canvas.toDataURL('image/webp')

    const [, imageBase64] = base64.split(',')

    const res = await uploadGameImage(id, imageBase64)

    //saveFile(imageBase64, 'webp')
    if (res.status === 0) {
      const game = games.find((value) => value._id === id.toString())
      if (game != null) {
        game.imgUrl = res.data?.toString() ?? ''
        setGames([...games])
      }
    }
  }

  async function fetchVendors() {
    try {
      const rawData = await getVendors()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setVendors(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }
  async function fetchGames() {
    try {
      console.log(vendor)
      if (vendor == null) {
        return
      }
      const rawData = await getGames(vendor)
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setGames(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchGames()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchVendors()
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vendor}
          label="벤더"
          onChange={(event) => {
            searchParams.set('vendor', event.target.value as string)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {vendors.map((value) => (
            <MenuItem key={value.code} value={value.code}>
              {`${value.nameKo} (${value.code})`}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Table sx={{ maxWidth: 1300, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>순서</TableCell>
            <TableCell align="center">코드</TableCell>
            <TableCell align="center">영문 이름</TableCell>
            <TableCell align="center">한글 이름</TableCell>
            <TableCell align="center">사용 여부</TableCell>
            <TableCell align="center">이미지</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games.map((row, index) => (
            <TableRow key={row._id}>
              <TableCell style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ textAlign: 'center', margin: '10px 10px 10px 10px' }}>{row.order}</div>
                <Button
                  variant="outlined"
                  onClick={async () => {
                    if (vendor != null) {
                      const moveToTopRes = await gamesMoveToTop(vendor, row.code)
                      if (moveToTopRes.status !== 0) {
                        return
                      }
                      const newGames = [moveToTopRes.data, ...games.filter((value) => value !== row)]
                      newGames.sort((a, b) => b.order - a.order)

                      setGames(newGames)
                    }
                  }}
                >
                  상단
                </Button>
                <Button
                  variant="outlined"
                  onClick={async () => {
                    if (vendor != null) {
                      const moveToTopRes = await gamesMoveToBottom(vendor, row.code)
                      if (moveToTopRes.status !== 0) {
                        return
                      }
                      const newGames = [moveToTopRes.data, ...games.filter((value) => value !== row)]
                      newGames.sort((a, b) => b.order - a.order)

                      setGames(newGames)
                    }
                  }}
                >
                  하단
                </Button>
              </TableCell>
              <TableCell align="left">{row.code}</TableCell>
              <TableCell align="left">{row.nameEn}</TableCell>
              <TableCell align="left">
                <div style={{ display: 'flex' }}>
                  <div style={{ textAlign: 'center', margin: '10px 10px 10px 10px' }}>{row.nameKo}</div>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSelectedItem(row)
                      setOpenChangeNameDialog(true)
                    }}
                  >
                    수정
                  </Button>
                </div>
              </TableCell>
              <TableCell align="center">
                <Switch
                  checked={row.used === 'y'}
                  onChange={async (event) => {
                    const res = await modifyGame({
                      _id: row._id.toString(),
                      setObj: {
                        used: row.used === 'y' ? 'n' : 'y',
                      },
                    })
                    if (res.status === 0) {
                      const game = games.find((value) => value._id === row._id.toString())
                      if (game != null) {
                        game.used = row.used === 'y' ? 'n' : 'y'
                        setGames([...games])
                      }
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                  }}
                >
                  <label htmlFor={row._id.toString() ?? ''}>
                    <img width="200" height="200" src={row.imgUrl} />
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    id={row._id.toString() ?? ''}
                    onChange={(event) => {
                      console.log(row)
                      console.log(event)
                      const files = event.target.files

                      if (files == null || files.length === 0) return
                      changeImage(files[0], row._id.toString())
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ChangeNameDialog
        open={openChangeNameDialog}
        gameItem={selectedItem}
        onClose={(setObj) => {
          if (selectedItem != null && setObj != null && Object.keys(setObj).length > 0) {
            selectedItem.nameKo = setObj.nameKo ?? selectedItem.nameKo
            setGames([...games])
          }

          setOpenChangeNameDialog(false)
        }}
      ></ChangeNameDialog>
    </div>
  )
}

export default GameListPage
