import { Dialog, DialogContent, DialogTitle } from '@mui/material'

export function DetailDialog({
  onClose,
  open,
  content,
}: {
  onClose: (updated: boolean) => void
  content: string
  open: boolean
}) {
  const borderColor = 'lightgrey'
  return (
    <Dialog
      onClose={() => {
        onClose(false)
      }}
      open={open}
      maxWidth="xl"
    >
      <DialogTitle>상세</DialogTitle>
      <DialogContent>
        <pre
          style={{
            padding: '10px',
            margin: 0,
            borderRadius: '0 0 2px 2px',
            borderTop: 'solid 1px ' + borderColor,
          }}
        >
          <code>{content}</code>
        </pre>
      </DialogContent>
    </Dialog>
  )
}
