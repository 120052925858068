import React, { useState, useEffect } from 'react'

import { getFakeCalu, uploadGameImage } from '../../api'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { getFileImage } from '../../util'
import { GameItem } from '../../api.interface'

const { format } = require('number-currency-format')

console.log(process.env.REACT_APP_API)

const minimumWidth = 500

const minimumHeight = 500

export const numTomoeny = (moeny: any) => {
  return format(moeny, { decimalsDigits: 0, decimalSeparator: '' })
}

function FakeCalculate() {
  const [needUpdate, setNeedUpdate] = useState(false)
  const [selectedVendor, setSelectedVendor] = useState('')
  const [cals, setCals] = useState<any[]>([])
  const [games, setGames] = useState<GameItem[]>([])

  async function changeImage(file: File, id: string) {
    const image = await getFileImage(file)

    // 이미지를 webp로 변환
    const canvas = document.createElement('canvas')

    canvas.width = image.width
    canvas.height = image.height

    //이미지가 너무 크면 적당히 리사이징해서 webp로 저장한다.
    if (canvas.width >= canvas.height) {
      if (canvas.width > minimumWidth) {
        canvas.height = Math.round((minimumWidth / canvas.width) * canvas.height)
        canvas.width = minimumWidth
      }
    } else {
      if (canvas.height > minimumHeight) {
        canvas.width = Math.round((minimumHeight / canvas.height) * canvas.width)
        canvas.height = minimumHeight
      }
    }

    const ctx = canvas.getContext('2d')
    if (ctx == null) {
      return
    }
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height) // draw the image

    console.log('save image')
    // do some manipulations...
    const base64 = canvas.toDataURL('image/webp')

    const [, imageBase64] = base64.split(',')

    const res = await uploadGameImage(id, imageBase64)

    //saveFile(imageBase64, 'webp')
    if (res.status === 0) {
      const game = games.find((value) => value._id === id.toString())
      if (game != null) {
        game.imgUrl = res.data?.toString() ?? ''
        setGames([...games])
      }
    }
  }

  async function fetchFakeCalu() {
    try {
      const rawData = await getFakeCalu()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setCals(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }
  async function fetchGames() {
    try {
      //   console.log(selectedVendor)
      //   const rawData = await getGames(selectedVendor)
      //   console.log(rawData)
      //   if (rawData.data == null) {
      //     throw 'error packet'
      //   }
      //   setGames(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchGames()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchFakeCalu()
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedVendor}
          label="Age"
          onChange={(event) => {
            setSelectedVendor(event.target.value as string)
            setNeedUpdate(true)
          }}
        >
          {vendors.map((value) => (
            <MenuItem key={value.code} value={value.code}>
              {value.nameKo}
            </MenuItem>
          ))}
        </Select> */}
      </div>
      <Table sx={{ maxWidth: 1200, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>날짜</TableCell>
            <TableCell align="center">
              <div>오리지널 Bet</div>
              <div>오리지널 win</div>
              <div>Bet-win</div>
            </TableCell>
            <TableCell align="center">
              <div>Fake Bet</div>
              <div>Fake win</div>
              <div>Bet-win</div>
            </TableCell>
            <TableCell align="center">
              <div>합산 Bet</div>
              <div>합산 win</div>
              <div>합산 Bet-win</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cals.map((row, index) => {
            const fakeWin = Object.keys(row.fake)
              .map((kkk: any) => {
                if (kkk.indexOf('_win') >= 0) {
                  return row.fake[kkk]
                }
                return 0
              })
              .reduce((a, b) => a + b, 0)

            const orgWin = Object.keys(row.org)
              .map((kkk: any) => {
                if (kkk.indexOf('_win') >= 0) {
                  return row.org[kkk]
                }
                return 0
              })
              .reduce((a, b) => a + b, 0)

            const fakeBet = Object.keys(row.fake)
              .map((kkk: any) => {
                if (kkk.indexOf('_win') === -1) {
                  return row.fake[kkk]
                }
                return 0
              })
              .reduce((a, b) => a + b, 0)
            const orgBet = Object.keys(row.org)
              .map((kkk: any) => {
                if (kkk.indexOf('_win') === -1) {
                  return row.org[kkk]
                }
                return 0
              })
              .reduce((a, b) => a + b, 0)

            return (
              <TableRow key={row.date}>
                <TableCell>{row.date}</TableCell>
                <TableCell align="center">
                  <div>{numTomoeny(orgBet)}</div>
                  <div>{numTomoeny(orgWin)}</div>
                  <div>{numTomoeny(orgBet - orgWin)}</div>
                </TableCell>
                <TableCell align="center">
                  <div>{numTomoeny(fakeBet)}</div>
                  <div>{numTomoeny(fakeWin)}</div>
                  <div>{numTomoeny(fakeBet - fakeWin)}</div>
                </TableCell>
                <TableCell align="center">
                  <div>{numTomoeny(orgBet - fakeBet)}</div>
                  <div>{numTomoeny(orgWin - fakeWin)}</div>
                  <div>{numTomoeny(orgBet - orgWin - (fakeBet - fakeWin))}</div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeCalculate
