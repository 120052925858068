import React, { useState, useEffect } from 'react'

import { getEntryLogs, getVendors } from '../../api'
import { Button, Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useSearchParams } from 'react-router-dom'
import { DetailDialog } from '../../component/DetailDialog'

console.log(process.env.REACT_APP_API)

const errorTypeTable = {
  vendor: '벤더',
  system: '시스템',
  network: '네트워크',
} as { [key: string]: string }

function UserEntryLog() {
  const [loading, setLoading] = useState(false)
  const [selectedLog, setSelectedLog] = useState<any>([])
  const [errorLogs, setErrorLogs] = useState<any[]>([])
  const [needUpdate, setNeedUpdate] = useState(false)
  const [openDetailDialog, setOpenDetailDialog] = useState(false)
  const [showOnlyErrorLog, setShowOnlyErrorLog] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [userId, setUserId] = useState<string>()
  const vendorParam = searchParams.get('vendor') ?? undefined
  const userIdParam = searchParams.get('userId') ?? undefined
  const [vendors, setVendors] = useState<{ code: string; nameKo: string }[]>([])
  console.log('UserEntryLogPage : ' + vendorParam)

  async function fetchData() {
    try {
      setLoading(true)
      const rawData = await getEntryLogs({
        ...(vendorParam != null && { vendor: vendorParam }),
        ...(userIdParam != null && { userId: userIdParam }),
        error: showOnlyErrorLog,
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const data = rawDatas.map((value) => ({
        ...value,
        timestamp: new Date(value.timestamp),
        errorType: errorTypeTable[value.stepLog[value.lastStep]?.error?.type as string] ?? '',
        errorCode: value.stepLog[value.lastStep]?.error?.code,
        errorMessage: value.stepLog[value.lastStep]?.error?.message,
      }))

      console.log(data)

      setErrorLogs(data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
    setLoading(false)
  }

  async function fetchVendors() {
    try {
      const rawData = await getVendors()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setVendors([
        {
          code: 'total',
          nameKo: '전체',
        },
        ...rawData.data,
      ])
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchVendors()
    fetchData()
    setUserId(userIdParam ?? '')
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1200px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vendorParam ?? 'total'}
          label="벤더"
          onChange={(event) => {
            if (event.target.value === 'total') {
              searchParams.delete('vendor')
            } else {
              searchParams.set('vendor', event.target.value as string)
            }
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {vendors.map((value) => (
            <MenuItem key={value.code} value={value.code}>
              {`${value.nameKo} (${value.code})`}
            </MenuItem>
          ))}
        </Select>
        <TextField
          style={{ minWidth: '100px', margin: '0px 10px' }}
          id="outlined-basic"
          label="유저 ID"
          value={userId}
          onChange={(evt) => {
            setUserId(evt.target.value)
          }}
        />
        <FormControlLabel
          style={{ minWidth: '100px', margin: '0px 10px' }}
          control={
            <Checkbox
              value={showOnlyErrorLog}
              onChange={(event) => {
                setShowOnlyErrorLog(event.target.checked)
                setNeedUpdate(true)
              }}
            />
          }
          label="에러 로그만 보기"
        />
        <Button
          variant="contained"
          onClick={() => {
            if (userId == null || userId === '') {
              searchParams.delete('userId')
            } else {
              searchParams.set('userId', userId as string)
            }
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          검색
        </Button>
      </div>
      <DataGrid
        style={{ width: '100%', height: '100%' }}
        getRowId={(row) => row._id}
        rows={errorLogs}
        columns={[
          {
            field: 'timestamp',
            headerName: '시간',
            width: 200,
            valueFormatter: (params) => params.value?.toLocaleString(),
          },
          {
            field: 'agentId',
            headerName: '에이전트 ID',
            width: 120,
          },
          {
            field: 'agentCode',
            headerName: '에이전트 코드',
            width: 100,
          },
          {
            field: 'userId',
            headerName: '유저 ID',
            width: 150,
          },
          {
            field: 'vendor',
            headerName: '벤더 코드',
            width: 100,
          },
          {
            field: 'gameCode',
            headerName: '게임 코드',
            width: 300,
          },
          {
            field: 'errorType',
            headerName: '에러 요소',
            width: 100,
          },
          {
            field: 'errorCode',
            headerName: '에러 코드',
            width: 100,
          },
          {
            field: 'errorMessage',
            headerName: '에러 메시지',
            width: 400,
          },
          {
            field: 'detailAction',
            headerName: '상세',
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            width: 100,
            renderCell: (params) => {
              return (
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={(e: any) => {
                      e.stopPropagation() // don't select this row after clicking
                      setSelectedLog(params.row)
                      setOpenDetailDialog(true)
                    }}
                  >
                    상세
                  </Button>
                </div>
              )
            },
          },
        ]}
        pageSize={100}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        loading={loading}
      />
      <DetailDialog
        open={openDetailDialog}
        content={JSON.stringify(selectedLog, null, 2)}
        onClose={(updated: boolean) => {
          setOpenDetailDialog(updated)
        }}
      />
    </div>
  )
}

export default UserEntryLog
