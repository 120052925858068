import React, { useState, useEffect } from 'react'

import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid'

import { getFakeBets } from '../../api'
import { Button, LinearProgress, TextField } from '@mui/material'
import { DetailDialog } from '../../component/DetailDialog'
import { useSearchParams } from 'react-router-dom'
import { setUrlSearchParamsValue } from '../../util'
import { FakeBetData } from '../../api.interface'
import { FakeDetailDialog } from '../../component/FakeDetailDialog'

console.log(process.env.REACT_APP_API)

function FakeBettingSearch() {
  const [needUpdate, setNeedUpdate] = useState(false)
  const [gameId, setGameId] = useState('')
  const [roundId, setRoundId] = useState('')
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  const [bettings, setBettings] = useState<FakeBetData[]>([])
  const [selectedBetting, setSelectedBetting] = React.useState<any>({})
  const [openDetailDialog, setOpenDetailDialog] = React.useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const userIdParam = searchParams.get('userId') ?? undefined
  const gameIdParam = searchParams.get('gameId') ?? undefined
  const roundIdParam = searchParams.get('roundId') ?? undefined

  const logColumes: GridColumns = [
    {
      field: 'idx',
      headerName: '번호',
      align: 'center',
      headerAlign: 'center',
      width: 70,
    },
    {
      field: 'username',
      headerName: '유저',
      width: 120,
    },
    {
      field: 'summaryId',
      headerName: 'SummaryId',
      width: 450,
    },
    {
      field: 'gameId',
      headerName: 'gameId',
      width: 200,
    },
    {
      field: 'roundId',
      headerName: 'roundId',
      width: 230,
    },
    {
      field: 'betTime',
      headerName: '베팅시간',
      width: 200,
      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'amountBet',
      headerName: '베팅금',
      align: 'right',
      headerAlign: 'right',
      width: 120,

      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'betOrg',
      headerName: '베팅 내역',
      align: 'right',
      headerAlign: 'right',
      width: 200,

      //valueFormatter: (params) => JSON.stringify(params.value ?? {}),
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ width: '100%' }}>
          {Object.entries(params.value ?? {}).map(([key, value]) => {
            return (
              <div
                id={key}
                style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}
              >
                <div style={{ textAlign: 'left' }}>{key}</div>
                <div style={{ textAlign: 'right' }}>{(value as number)?.toLocaleString() ?? value}</div>
              </div>
            )
          })}
        </div>
      ),
    },
    {
      field: 'fakeAmountBet',
      headerName: '페이크 베팅금',
      align: 'right',
      headerAlign: 'right',
      width: 120,

      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'detailAction',
      headerName: '상세',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              color="success"
              onClick={(e: any) => {
                e.stopPropagation() // don't select this row after clicking
                setSelectedBetting(params.row)
                setOpenDetailDialog(true)
              }}
            >
              상세
            </Button>
          </div>
        )
      },
    },
  ]

  async function fetchData(continueData = false) {
    setLoading(true)

    try {
      const rawData = await getFakeBets({
        ...(userIdParam != null && { userId: userIdParam }),
        ...(gameIdParam != null && { gameId: gameIdParam }),
        ...(roundIdParam != null && { roundId: roundIdParam }),
        ...(continueData && bettings.length > 0 && { continueId: bettings[bettings.length - 1]._id }),
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const newData: FakeBetData[] = rawDatas.map((value) => {
        return {
          ...value,
          betTime: new Date(value.betTime),
          username: value.agentCode + value.userId,
        }
      })

      const updateData = continueData ? [...bettings, ...newData] : newData

      const indexedDatas = updateData.map((value, idx) => {
        const amountBet = Object.values(value.betOrg ?? {}).reduce((prev, curr) => {
          return prev + curr
        }, 0)
        const fakeAmountBet = Object.values(value.betFake ?? {}).reduce((prev, curr) => {
          return prev + curr
        }, 0)

        return { ...value, amountBet, fakeAmountBet, idx: idx + 1 }
      })

      setBettings(indexedDatas)
      setLoading(false)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    setUserId(userIdParam ?? '')
    setRoundId(roundIdParam ?? '')
    setGameId(gameIdParam ?? '')
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', minWidth: '2000px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          width: '100%',
        }}
      >
        <TextField
          style={{ maxWidth: '150px', margin: '0px 10px' }}
          id="outlined-basic"
          label="유저ID"
          fullWidth
          value={userId}
          onChange={(evt) => {
            setUserId(evt.target.value)
          }}
        />
        <TextField
          style={{ maxWidth: '200px', margin: '0px 10px' }}
          id="outlined-basic"
          label="게임ID"
          fullWidth
          value={gameId}
          onChange={(evt) => {
            setGameId(evt.target.value)
          }}
        />
        <TextField
          style={{ maxWidth: '200px', margin: '0px 10px' }}
          id="outlined-basic"
          label="라운드ID"
          fullWidth
          value={roundId}
          onChange={(evt) => {
            setRoundId(evt.target.value)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            setUrlSearchParamsValue(searchParams, 'userId', userId)
            setUrlSearchParamsValue(searchParams, 'gameId', gameId)
            setUrlSearchParamsValue(searchParams, 'roundId', roundId)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
        <Button
          style={{ minWidth: '100px', marginLeft: '10px' }}
          variant="contained"
          onClick={() => {
            fetchData(true)
          }}
        >
          {`데이터 추가 로딩 (${bettings.length})`}
        </Button>
      </div>
      <DataGrid
        style={{ width: '100%', height: '100%' }}
        components={{ LoadingOverlay: LinearProgress }}
        getRowId={(row) => row._id}
        getRowHeight={() => 'auto'}
        rows={bettings}
        columns={logColumes}
        pageSize={100}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        loading={loading}
      />
      <FakeDetailDialog
        open={openDetailDialog}
        roundId={selectedBetting.roundId}
        username={selectedBetting.agentCode + selectedBetting.userId}
        onClose={(updated: boolean) => {
          setOpenDetailDialog(updated)
        }}
      />
    </div>
  )
}

export default FakeBettingSearch
