//import List from '@mui/material/List'
//import ListItemText from '@mui/material/ListItemText'
import './SideMenu.css'
import { List, ListItemButton, ListSubheader, ListItemText, Divider } from '@mui/material'

function SideMenu() {
  return (
    <div className="sidebar">
      <List>
        <ListSubheader component="div" inset>
          공통
        </ListSubheader>
        <ListItemButton component="a" href="/">
          <ListItemText primary={'홈'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/vendorlist">
          <ListItemText primary={'벤더 리스트'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/gamelist">
          <ListItemText primary={'게임 리스트'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/agentList">
          <ListItemText primary={'에이전트 리스트'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/userList">
          <ListItemText primary={'유저 리스트'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/new-games">
          <ListItemText primary={'최신 게임'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/agent-vendor-setting">
          <ListItemText primary={'에이전트 벤더 셋팅'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/agent-game-setting">
          <ListItemText primary={'에이전트 게임 셋팅'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/agent-transactions">
          <ListItemText primary={'에이전트 트랜잭션'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/agent-login-log">
          <ListItemText primary={'에이전트 로긴 로그'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/user-transactions">
          <ListItemText primary={'유저 트랜잭션'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/user-entry-log">
          <ListItemText primary={'유저 접속 로그'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/betting-search">
          <ListItemText primary={'베팅 찾기'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/whitelist">
          <ListItemText primary={'화이트리스트'} />
        </ListItemButton>
      </List>
      <Divider sx={{ my: 1 }} />
      <List>
        <ListSubheader component="div" inset>
          페이크 관리
        </ListSubheader>
        <ListItemButton component="a" href="#/bettinglist">
          <ListItemText primary={'페이크 베팅 리스트'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-betting-search">
          <ListItemText primary={'페이크 베팅 찾기'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-game-round">
          <ListItemText primary={'페이크 게임 라운드'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-entry-error-log">
          <ListItemText primary={'페이크 접속 에러 로그'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-connection-log">
          <ListItemText primary={'페이크 커넥션 로그'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-chat-log">
          <ListItemText primary={'페이크 채팅 로그'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-socket">
          <ListItemText primary={'페이크 소켓'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-blacklist">
          <ListItemText primary={'페이크 블랙리스트'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-seamless">
          <ListItemText primary={'페이크 심리스'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-table">
          <ListItemText primary={'페이크 테이블'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-domain">
          <ListItemText primary={'페이크 도메인'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-server-domain">
          <ListItemText primary={'페이크 서버 도메인'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-redirect">
          <ListItemText primary={'페이크 리디렉트'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-sub-redirect">
          <ListItemText primary={'페이크 서브 리디렉트'} />
        </ListItemButton>
      </List>
      <Divider sx={{ my: 1 }} />
      <List>
        <ListSubheader component="div" inset>
          통계
        </ListSubheader>
        <ListItemButton component="a" href="#/fake-daily-stats">
          <ListItemText primary={'일일 페이크 통계'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-daily-table">
          <ListItemText primary={'일일 논페이크 테이블 통계'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-daily-agent">
          <ListItemText primary={'일일 논페이크 에이전트 통계'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-daily-user">
          <ListItemText primary={'일일 페이크 유저 통계'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/daily-total-agent-vendor-usage">
          <ListItemText primary={'일일 전체 에이전트 사용량 통계'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/daily-agent-vendor-usage">
          <ListItemText primary={'일일 에이전트 사용량 통계'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/fake-calculate">
          <ListItemText primary={'Fake 정산'} />
        </ListItemButton>
        <ListItemButton component="a" href="#/calculate">
          <ListItemText primary={'정산'} />
        </ListItemButton>
      </List>
    </div>
  )
}

export default SideMenu
