import { useState, useEffect } from 'react'

import { FakeDomain, getFakeDomains, postEvoDomainRedirect } from '../../api'
import { Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem } from '@mui/material'

import { redirectColorTable, redirectMenuItems } from '../../util/redirect-items'

console.log(process.env.REACT_APP_API)

interface FakeServer {
  key: string
  name: string
  domainName?: string
}

function FakeServerDomainPage() {
  const [fakeServers, setFakeServers] = useState<FakeServer[]>(redirectMenuItems)
  const [fakeDomains, setFakeDomains] = useState<FakeDomain[]>([])

  async function fetchData() {
    try {
      const res = await getFakeDomains()
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      const newFakeServers = fakeServers

      for (const i of res.data) {
        if (i.redirectServer != null) {
          const server = newFakeServers.find((value) => value.key === i.redirectServer)
          if (server != null) {
            server.domainName = i.domainName
          }
        }
      }

      setFakeServers(newFakeServers)
      setFakeDomains(res.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="Log">
      <Table sx={{ maxWidth: 'sm' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>서버</TableCell>
            <TableCell>리디렉트서버</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fakeServers.map((value) => {
            return (
              <TableRow key={value.key}>
                <TableCell>{value.name}</TableCell>
                <TableCell align="center">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.domainName ?? ''}
                    style={{ backgroundColor: redirectColorTable[value.domainName ?? ''] ?? 'white' }}
                    label="리디렉트"
                    onChange={async (event) => {
                      const res = await postEvoDomainRedirect({
                        domainName: event.target.value,
                        redirectServer: value.key,
                      })
                      if (res.status === 0) {
                        const updated = fakeServers.find((server) => server.key === value.key)
                        if (updated != null) {
                          updated.domainName = event.target.value
                          setFakeServers([...fakeServers])
                        }
                      }
                    }}
                  >
                    {fakeDomains.map(({ domainName }) => (
                      <MenuItem id={domainName} value={domainName}>
                        {domainName}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeServerDomainPage
