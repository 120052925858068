import { useState, useEffect } from 'react'

import { getAgents, getAgentTransactions, getUserTransactions } from '../../api'
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { Agent } from '../../api.interface'

console.log(process.env.REACT_APP_API)

interface UserTransaction {
  regDate: Date
  type: string
  userId: string
  balance: number
  affterBalance: number
  affterUserBalance: number
  status?: string
  error?: string
}

function AgentTransactionsPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const agentIdParam = searchParams.get('agentId')
  console.log('AgentTransactionsPage : ' + agentIdParam)

  //const [tempUsername, setTempUsername] = useState(agentId)

  const [needUpdate, setNeedUpdate] = useState(false)
  const [transactions, setTransactions] = useState<UserTransaction[]>([])
  const [agents, setAgents] = useState<Agent[]>([])

  function fetchCondtion() {
    return agentIdParam !== ''
  }

  async function fetchData() {
    if (fetchCondtion() === false) {
      return
    }

    if (agentIdParam == null) {
      return
    }

    const params = {
      agentId: agentIdParam,
    }

    try {
      const rawData = await getAgentTransactions(params)
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const datas = rawDatas.map((value, idx) => {
        return { ...value, idx: idx + 1, date: new Date(value.date) }
      })

      setTransactions(datas)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }
  async function fetchAgents() {
    try {
      if (agents?.length !== 0) {
        return
      }
      const rawData = await getAgents({})
      console.log(rawData)

      if (rawData.status !== 0) {
        throw 'getAgents error packet'
      }

      setAgents(rawData.data.filter((value) => value.status === 'reg'))
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    if (fetchCondtion()) {
      fetchData()
    }
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    if (fetchCondtion()) {
      fetchData()
    }
    fetchAgents()
    //fetchData({date:currentDate})
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1300px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          width: '100%',
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={agentIdParam}
          label="Agent"
          onChange={(event) => {
            searchParams.set('agentId', event.target.value as string)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {agents.map((value) => (
            <MenuItem key={value.agentId} value={value.agentId}>
              {`${value.agentId} (${value.nick})`}
            </MenuItem>
          ))}
        </Select>
        <Button
          style={{ minWidth: '100px', marginLeft: '20px' }}
          variant="contained"
          onClick={() => {
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
      </div>
      <Table sx={{ maxWidth: 800, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">시간</TableCell>
            <TableCell align="center" width="30px">
              타입
            </TableCell>
            <TableCell align="center">유저</TableCell>
            <TableCell align="center">증감</TableCell>
            <TableCell align="center">에이전트 잔금</TableCell>
            <TableCell align="center">유저 잔금</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{new Date(row.regDate)?.toLocaleString()}</TableCell>
              <TableCell align="left">{row.type === 'agent_give_balance' ? '지급' : '회수'}</TableCell>

              <TableCell align="right">{row.userId?.toLocaleString()}</TableCell>
              <TableCell align="right">{row.balance?.toLocaleString()}</TableCell>
              <TableCell align="right">{row.affterBalance?.toLocaleString()}</TableCell>
              <TableCell align="right">{row.affterUserBalance?.toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AgentTransactionsPage
