import { useState, useEffect, Fragment } from 'react'

import { addDays, addMonths, format } from 'date-fns'
import { Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Button from '@mui/material/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { getDailyNonFakeTables } from '../../api'

console.log(process.env.REACT_APP_API)

const numberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

interface EvolutionTableRow {
  tableId: string
  tableName: string
  amountBet: number
  amountWin: number
  profit: number
}

interface CustomDailyStat {
  day: string
  data: {
    amountBet: number
    amountWin: number
    profit: number
    sortedTables: EvolutionTableRow[]
  }
}

function FakeDailyTablePage() {
  const [dailyStats, setDailyStats] = useState<CustomDailyStat[]>([])
  const [totalTables, setTotalTables] = useState<EvolutionTableRow[]>([])
  //const [yearMonth, setYearMonth] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  const params = new URLSearchParams(location.search)

  const currentYearMonth = params.get('month') ?? format(new Date(), 'yyyy-MM')

  console.log('currentYearMonth : ' + currentYearMonth)

  async function fetchStats(day: string) {
    try {
      const dailyStat = dailyStats.find((value) => value.day === day)

      if (dailyStat == null) {
        return
      }

      setLoading(true)

      const rawData = await getDailyNonFakeTables({
        day,
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const newTables = rawData.data.tables

      let amountBet = 0
      let amountWin = 0
      let profit = 0

      const sortedTables: EvolutionTableRow[] = []
      for (const tableId in newTables) {
        const table = newTables[tableId]
        amountBet += table.amountBet
        amountWin += table.amountWin
        profit += table.amountBet - table.amountWin

        sortedTables.push({
          tableId,
          ...table,
          profit: table.amountBet - table.amountWin,
        })
      }

      sortedTables.sort((a, b) => b.profit - a.profit)

      dailyStat.data = {
        amountBet,
        amountWin,
        profit,
        sortedTables,
      }

      const totalTableDict: { [id: string]: EvolutionTableRow } = {}

      for (const {
        data: { sortedTables },
      } of dailyStats) {
        for (const table of sortedTables) {
          const totalTable = (totalTableDict[table.tableId] ??= { ...table, amountBet: 0, amountWin: 0, profit: 0 })
          totalTable.amountBet += table.amountBet
          totalTable.amountWin += table.amountWin
          totalTable.profit += table.profit
        }
      }

      const totalTables = Object.values(totalTableDict)

      totalTables.sort((a, b) => b.profit - a.profit)

      setTotalTables(totalTables)

      setDailyStats([...dailyStats])
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }

    setLoading(false)
  }

  function Row(props: { row: CustomDailyStat; loading: boolean }) {
    const { row, loading } = props
    const [open, setOpen] = useState(false)

    return (
      <Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center">
            {
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => {
                  fetchStats(row.day)
                }}
              >
                {`계산`}
              </Button>
            }
          </TableCell>
          <TableCell align="center">{row.day?.toLocaleString()}</TableCell>
          <TableCell align="right">
            <div>{numberFormat.format(row.data.amountBet ?? 0)}</div>
          </TableCell>
          <TableCell align="right">
            <div>{numberFormat.format(row.data.amountWin ?? 0)}</div>
          </TableCell>
          <TableCell align="right">
            <div>{numberFormat.format(row.data.profit ?? 0)}</div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>이름</TableCell>
                      <TableCell align="right">베팅금</TableCell>
                      <TableCell align="right">승리금</TableCell>
                      <TableCell align="right">이익금</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.data.sortedTables.map((tableRow) => (
                      <TableRow key={tableRow.tableId}>
                        <TableCell component="th" scope="row">
                          {tableRow.tableId}
                        </TableCell>
                        <TableCell>{tableRow.tableName}</TableCell>
                        <TableCell align="right">{tableRow.amountBet}</TableCell>
                        <TableCell align="right">{tableRow.amountWin}</TableCell>
                        <TableCell align="right">{tableRow.profit}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }

  console.log('FakeDailyStatsPage render')

  function updateMonth(month: string) {
    console.log('updateMonth')
    const now = new Date()

    const firstDay = new Date(new Date(month).getTime() + now.getTimezoneOffset() * 60_000)

    const dailyStats: CustomDailyStat[] = []
    for (
      let currentDay = firstDay;
      currentDay.getMonth() === firstDay.getMonth() && currentDay < now;
      currentDay = addDays(currentDay, 1)
    ) {
      const day = format(currentDay, 'yyyy-MM-dd')

      //console.log(currentDay)

      dailyStats.push({
        day,
        data: {
          amountBet: 0,
          amountWin: 0,
          profit: 0,
          sortedTables: [],
        },
      })
    }

    setDailyStats(dailyStats)
  }

  useEffect(() => {
    console.log('useEffect')
    updateMonth(currentYearMonth)
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', minWidth: 800, maxWidth: 1000 }}>
      <div style={{ alignSelf: 'center' }}>
        <Button
          onClick={() => {
            const lastMonth = addMonths(new Date(currentYearMonth), -1)

            const params = new URLSearchParams(location.search)
            const month = format(lastMonth, 'yyyy-MM')
            params.set('month', month)

            console.log(params.toString())

            navigate('?' + params.toString())
            updateMonth(month)
          }}
        >
          {'<<'}
        </Button>
        {currentYearMonth}
        <Button
          onClick={() => {
            const nextMonth = addMonths(new Date(currentYearMonth), 1)

            const params = new URLSearchParams(location.search)
            const month = format(nextMonth, 'yyyy-MM')
            params.set('month', month)

            console.log(params.toString())

            navigate('?' + params.toString())
            updateMonth(month)
          }}
        >
          {'>>'}
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <Table sx={{ marginLeft: '10px', minWidth: '700px' }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">계산</TableCell>
              <TableCell align="center">날짜</TableCell>
              <TableCell align="center">베팅머니</TableCell>
              <TableCell align="center">승리머니</TableCell>
              <TableCell align="center">수익</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dailyStats.map((row, index) => (
              <Row key={row.day} loading={loading} row={row}></Row>
            ))}
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left">총합</TableCell>
              <TableCell align="right">
                {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.data.amountBet ?? 0), 0))}
              </TableCell>
              <TableCell align="right">
                {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.data.amountWin ?? 0), 0))}
              </TableCell>
              <TableCell align="right">
                {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.data.profit ?? 0), 0))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box style={{ width: '1px', backgroundColor: 'black', marginLeft: '10px', marginRight: '10px' }}></Box>
        <Table sx={{ marginLeft: '10px', minWidth: '700px' }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>이름</TableCell>
              <TableCell align="right">베팅금</TableCell>
              <TableCell align="right">승리금</TableCell>
              <TableCell align="right">이익금</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalTables.map((tableRow) => (
              <TableRow key={tableRow.tableId}>
                <TableCell component="th" scope="row">
                  {tableRow.tableId}
                </TableCell>
                <TableCell>{tableRow.tableName}</TableCell>
                <TableCell align="right">{tableRow.amountBet}</TableCell>
                <TableCell align="right">{tableRow.amountWin}</TableCell>
                <TableCell align="right">{tableRow.profit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default FakeDailyTablePage
