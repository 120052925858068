import { useState, useEffect } from 'react'

import { getBlacklistUsers, FakeBlacklistUser, deleteBlacklistUser } from '../../api'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'

console.log(process.env.REACT_APP_API)

function FakeBlacklistPage() {
  const [needUpdate, setNeedUpdate] = useState(false)
  const [blacklistUsers, setBlacklistUsers] = useState<FakeBlacklistUser[]>([])

  async function fetchData() {
    try {
      const rawData = await getBlacklistUsers()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setBlacklistUsers(
        rawData.data.map((value) => ({
          ...value,
          createdAt: new Date(value.createdAt),
        })),
      )
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', minWidth: '600px' }}>
      <Table sx={{ marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="100px" align="center">
              등록 시간
            </TableCell>
            <TableCell align="center">유저 이름</TableCell>
            <TableCell align="center">접속 ip</TableCell>
            <TableCell align="center">접속 헤더</TableCell>
            <TableCell align="center">기능</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blacklistUsers.map((row, index) => (
            <TableRow key={row.username}>
              <TableCell align="left">
                <div>{row.createdAt.toLocaleDateString()}</div>
                <div>{row.createdAt.toLocaleTimeString()}</div>
              </TableCell>
              <TableCell align="left">{row.agentId}</TableCell>
              <TableCell align="left">{row.username}</TableCell>
              <TableCell align="left">{row.ip}</TableCell>
              <TableCell align="left">
                <TextField
                  sx={{ width: '500px' }}
                  multiline
                  rows={7}
                  disabled={true}
                  value={JSON.stringify(row.headers, null, 2)}
                ></TextField>
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  component="label"
                  color="error"
                  onClick={() => {
                    deleteBlacklistUser({ username: row.username }).then(() => {
                      fetchData()
                    })
                  }}
                >
                  삭제
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeBlacklistPage
