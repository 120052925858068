import { useState } from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'

import './index.css'
import Login, { SessionContext, getSession } from './Login'

import SideMenu from './SideMenu'
import WhitelistPage from './page/common/Whitelist'
import GameListPage from './page/common/GameList'
import CalculatePage from './page/stats/Calculate'
import FakeCalculate from './page/stats/FakeCalculate'
import BettingSearch from './page/common/BettingSearch'
import FakeTable from './page/fake/FakeTable'
import FakeDomain from './page/fake/FakeDomain'
import FakeRedirectPage from './page/fake/FakeRedirect'
import VendorListPage from './page/common/VendorList'
//import BettingListPage from './page/FakeBettingList'
import FakeBettingListPage from './page/fake/FakeBettingList'
import AgentTransactionsPage from './page/common/AgentTransactions'
import AgentLoginLogPage from './page/common/AgentLoginLog'
import UserTransactionsPage from './page/common/UserTransactions'
import FakeSocketPage from './page/fake/FakeSocketList'
import FakeDailyStatsPage from './page/stats/FakeDailyStats'
import FakeBettingSearch from './page/fake/FakeBettingSearch'
import FakeEntryErrorLogPage from './page/fake/FakeEntryErrorLog'
import FakeSeamlessPage from './page/fake/FakeSeamlessList'
import FakeDailyTablePage from './page/stats/FakeDailyTable'
import NewGamesPage from './page/common/NewGames'
import FakeDailyAgentPage from './page/stats/FakeDailyAgent'
import FakeDailyUserPage from './page/stats/FakeDailyUser'
import AgentVendorSettingPage from './page/common/AgentVendorSetting'
import UserEntryLog from './page/common/UserEntryLog'
import FakeGameRoundPage from './page/fake/FakeGameRound'
import AgentGameSettingPage from './page/common/AgentGameSetting'
import FakeBlacklistPage from './page/fake/FakeBlacklist'
import AgentListPage from './page/common/AgentList'
import UserListPage from './page/common/UserList'
import DailyAgentVendorUsage from './page/stats/DailyAgentVendorUsage'
import FakeConnectionLogPage from './page/fake/FakeConnectionLog'
import FakeChatLogPage from './page/fake/FakeChatLog'
import DailyTotalAgentVendorUsage from './page/stats/DailyTotalAgentVendorUsage'
import FakeSubRedirectPage from './page/fake/FakeSubRedirect'
import FakeServerDomainPage from './page/fake/FakeServerDomain'
export default function App() {
  const [session, setSession] = useState<string>(getSession())
  //const [session, setSession] = useState<string>()
  const authenticated = session == null ? false : true

  console.log('session', session)

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      {authenticated ? (
        <div style={{ display: 'flex' }}>
          <SideMenu />
          <HashRouter>
            <Routes>
              <Route path="vendorlist" element={<VendorListPage />} />
              <Route path="gamelist" element={<GameListPage />} />
              <Route path="agentlist" element={<AgentListPage />} />
              <Route path="userlist" element={<UserListPage />} />
              <Route path="new-games" element={<NewGamesPage />} />
              <Route path="whitelist" element={<WhitelistPage />} />
              <Route path="bettinglist" element={<FakeBettingListPage />} />
              <Route path="agent-transactions" element={<AgentTransactionsPage />} />
              <Route path="user-transactions" element={<UserTransactionsPage />} />
              <Route path="user-entry-log" element={<UserEntryLog />} />
              <Route path="agent-login-log" element={<AgentLoginLogPage />} />
              <Route path="agent-vendor-setting" element={<AgentVendorSettingPage />} />
              <Route path="agent-game-setting" element={<AgentGameSettingPage />} />
              <Route path="betting-search" element={<BettingSearch />} />
              <Route path="fake-betting-search" element={<FakeBettingSearch />} />
              <Route path="fake-game-round" element={<FakeGameRoundPage />} />
              <Route path="fake-entry-error-log" element={<FakeEntryErrorLogPage />} />
              <Route path="fake-connection-log" element={<FakeConnectionLogPage />} />
              <Route path="fake-chat-log" element={<FakeChatLogPage />} />
              <Route path="fake-socket" element={<FakeSocketPage />} />
              <Route
                path="fake-seamless"
                element={
                  <FakeSeamlessPage cluster="fake-seamless" serviceName="fake-seamless-srv" region="ap-southeast-1" />
                }
              />
              <Route path="fake-blacklist" element={<FakeBlacklistPage />} />
              <Route path="fake-table" element={<FakeTable />} />
              <Route path="fake-domain" element={<FakeDomain />} />
              <Route path="fake-server-domain" element={<FakeServerDomainPage />} />
              <Route path="fake-redirect" element={<FakeRedirectPage />} />
              <Route path="fake-sub-redirect" element={<FakeSubRedirectPage />} />
              <Route path="fake-daily-stats" element={<FakeDailyStatsPage />} />
              <Route path="fake-daily-table" element={<FakeDailyTablePage />} />
              <Route path="fake-daily-agent" element={<FakeDailyAgentPage />} />
              <Route path="fake-daily-user" element={<FakeDailyUserPage />} />
              <Route path="daily-agent-vendor-usage" element={<DailyAgentVendorUsage />} />
              <Route path="daily-total-agent-vendor-usage" element={<DailyTotalAgentVendorUsage />} />
              <Route path="calculate" element={<CalculatePage />} />
              <Route path="fake-calculate" element={<FakeCalculate />} />
            </Routes>
          </HashRouter>
        </div>
      ) : (
        <Login></Login>
      )}
    </SessionContext.Provider>
  )
}
