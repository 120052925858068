import { useState, useEffect } from 'react'

import { getDailyFakeStats } from '../../api'
import { BettingStat } from '../../api.interface'
import { addDays, addMonths, format } from 'date-fns'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Button from '@mui/material/Button'
import { useLocation, useNavigate } from 'react-router-dom'

console.log(process.env.REACT_APP_API)

const numberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

interface CustomBettingStat extends BettingStat {
  totalProfit: number
  orgProfit: number
  fakeProfit: number
  fakeUsage: number
}
interface CustomDailyStat {
  day: string
  total?: CustomBettingStat
}

function FakeDailyStatsPage() {
  const [dailyStats, setDailyStats] = useState<CustomDailyStat[]>([])
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  const params = new URLSearchParams(location.search)

  const currentYearMonth = params.get('month') ?? format(new Date(), 'yyyy-MM')

  console.log('currentYearMonth : ' + currentYearMonth)

  async function fetchStats(day: string) {
    try {
      const dailyStat = dailyStats.find((value) => value.day === day)

      if (dailyStat == null) {
        return
      }

      setLoading(true)

      const rawData = await getDailyFakeStats({
        day,
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const total = rawData.data.total

      const totalProfit = total.totalBetMoney - total.totalWinMoney
      const orgProfit = total.orgBetMoney - total.orgWinMoney
      const fakeProfit = total.fakeBetMoney - total.fakeWinMoney

      dailyStat.total = {
        ...total,
        totalProfit,
        orgProfit,
        fakeProfit,
        fakeUsage: totalProfit - fakeProfit,
      }
      setDailyStats([...dailyStats])
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }

    setLoading(false)
  }

  console.log('FakeDailyStatsPage render')

  function updateMonth(month: string) {
    console.log('updateMonth')
    const now = new Date()

    const firstDay = new Date(new Date(month).getTime() + now.getTimezoneOffset() * 60_000)

    const dailyStats: CustomDailyStat[] = []
    for (
      let currentDay = firstDay;
      currentDay.getMonth() === firstDay.getMonth() && currentDay < now;
      currentDay = addDays(currentDay, 1)
    ) {
      const day = format(currentDay, 'yyyy-MM-dd')

      //console.log(currentDay)

      dailyStats.push({
        day,
      })
    }

    setDailyStats(dailyStats)
  }

  useEffect(() => {
    console.log('useEffect')
    updateMonth(currentYearMonth)
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', minWidth: 800, maxWidth: 1000 }}>
      <div style={{ alignSelf: 'center' }}>
        <Button
          onClick={() => {
            const lastMonth = addMonths(new Date(currentYearMonth), -1)

            const params = new URLSearchParams(location.search)
            const month = format(lastMonth, 'yyyy-MM')
            params.set('month', month)

            console.log(params.toString())

            navigate('?' + params.toString())
            updateMonth(month)
          }}
        >
          {'<<'}
        </Button>
        {currentYearMonth}
        <Button
          onClick={() => {
            const nextMonth = addMonths(new Date(currentYearMonth), 1)

            const params = new URLSearchParams(location.search)
            const month = format(nextMonth, 'yyyy-MM')
            params.set('month', month)

            console.log(params.toString())

            navigate('?' + params.toString())
            updateMonth(month)
          }}
        >
          {'>>'}
        </Button>
      </div>
      <Table sx={{ marginLeft: '10px' }} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">계산</TableCell>
            <TableCell align="center">날짜</TableCell>
            <TableCell align="center">
              <div>전체베팅머니</div>
              <div>전체승리머니</div>
              <div>전체 수익(a)</div>
            </TableCell>

            <TableCell align="center">
              <div>페이크베팅머니</div>
              <div>페이크승리머니</div>
              <div>페이크 수익(b)</div>
            </TableCell>

            <TableCell align="center">
              <div>벤더베팅머니</div>
              <div>벤더승리머니</div>
              <div>벤더 수익(c)</div>
            </TableCell>

            <TableCell align="center">페이크 차익(a-c)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dailyStats.map((row, index) => (
            <TableRow key={row.day}>
              <TableCell align="center">
                {
                  <Button
                    variant="contained"
                    disabled={loading}
                    onClick={() => {
                      fetchStats(row.day)
                    }}
                  >
                    {`계산`}
                  </Button>
                }
              </TableCell>
              <TableCell align="center">{row.day?.toLocaleString()}</TableCell>
              <TableCell align="right">
                <div>{numberFormat.format(row.total?.totalBetMoney ?? 0)}</div>
                <div>{numberFormat.format(row.total?.totalWinMoney ?? 0)}</div>
                <div>{numberFormat.format(row.total?.totalProfit ?? 0)}</div>
              </TableCell>

              <TableCell align="right">
                <div>{numberFormat.format(row.total?.orgBetMoney ?? 0)}</div>
                <div>{numberFormat.format(row.total?.orgWinMoney ?? 0)}</div>
                <div>{numberFormat.format(row.total?.orgProfit ?? 0)}</div>
              </TableCell>

              <TableCell align="right">
                <div>{numberFormat.format(row.total?.fakeBetMoney ?? 0)}</div>
                <div>{numberFormat.format(row.total?.fakeWinMoney ?? 0)}</div>
                <div>{numberFormat.format(row.total?.fakeProfit ?? 0)}</div>
              </TableCell>

              <TableCell align="right">{numberFormat.format(row.total?.fakeUsage ?? 0)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">총합</TableCell>
            <TableCell align="right">
              <div>
                {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.totalBetMoney ?? 0), 0))}
              </div>
              <div>
                {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.totalWinMoney ?? 0), 0))}
              </div>
              <div>{numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.totalProfit ?? 0), 0))}</div>
            </TableCell>
            <TableCell align="right">
              <div>{numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.orgBetMoney ?? 0), 0))}</div>
              <div>{numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.orgWinMoney ?? 0), 0))}</div>
              <div>{numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.orgProfit ?? 0), 0))}</div>
            </TableCell>

            <TableCell align="right">
              <div>{numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.fakeBetMoney ?? 0), 0))}</div>
              <div>{numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.fakeWinMoney ?? 0), 0))}</div>
              <div>{numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.fakeProfit ?? 0), 0))}</div>
            </TableCell>

            <TableCell align="right">
              {numberFormat.format(dailyStats.reduce((acc, cur) => acc + (cur.total?.fakeUsage ?? 0), 0))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeDailyStatsPage
