import { useState, useEffect } from 'react'

import { getAgents } from '../../api'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Agent } from '../../api.interface'

console.log(process.env.REACT_APP_API)

//const redirectParamItems = ['honorlink-games.com', 'swix-games.net', 'union-games.org']

function AgentListPage() {
  const [agents, setAgents] = useState<Agent[]>([])

  async function fetchData() {
    try {
      const res = await getAgents({ showAll: true })
      console.log(res)

      if (res.data == null) {
        throw 'error packet'
      }

      res.data = res.data.map((value) => ({
        ...value,
        regDate: new Date(value.regDate),
        //agentTree: value.agentTree.replaceAll('|', ' > '),
      }))

      setAgents(res.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="Log">
      <div style={{ marginTop: '10px' }}></div>
      <Table sx={{ maxWidth: 1100, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Idx</TableCell>
            <TableCell align="center">에이전트 코드</TableCell>
            <TableCell align="center">에이전트 아이디</TableCell>
            <TableCell align="center">닉</TableCell>
            <TableCell align="center">에이전트 트리</TableCell>
            <TableCell align="center">상태</TableCell>
            <TableCell align="center">생성 날짜</TableCell>
            <TableCell align="center">벤더 셋팅</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agents.map((row, index) => {
            return (
              <TableRow key={row.idx}>
                <TableCell>{row.idx}</TableCell>
                <TableCell align="left">{row.agentCode}</TableCell>
                <TableCell align="left">{row.agentId}</TableCell>
                <TableCell align="left">{row.nick}</TableCell>
                <TableCell align="left">
                  {row.agentTree.split('|').map((value) => (
                    <div>{value}</div>
                  ))}
                </TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.regDate?.toLocaleString()}</TableCell>
                <TableCell align="left">
                  <Button variant="contained" href={`#/agent-vendor-setting?agent=${row.agentId}`}>
                    벤더 셋팅
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default AgentListPage
