import React, { useState, useEffect } from 'react'

import { ResponsiveBar } from '@nivo/bar'

import { depositAgentBalance, getCalculate, withdrawAgentBalance } from '../../api'
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'

import { GetMonthString } from '../../util'

const MyResponsiveBar = ({ data, keys }: { data: any; keys: any }) => {
  if (data == null || keys == null) {
    return <></>
  }
  return (
    <ResponsiveBar
      {...{
        height: 1200,
        groupMode: 'stacked',
      }}
      // groupMode="grouped"
      data={data}
      keys={keys}
      indexBy="date"
      //keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'nivo' }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'date',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'food',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
      }}
    />
  )
}

function DepositDialog(props: { onClose: (updated: boolean) => void; agent: any; open: boolean }) {
  const { onClose, agent, open } = props

  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>입금</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="금액"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            value={amount}
            onChange={(event) => {
              setAmount(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={async (e: any) => {
              console.log(amount)
              setLoading(true)
              await depositAgentBalance({ agentIdx: agent.idx, amount: Number(amount) })
              setLoading(false)
              onClose(true)
            }}
          >
            입금
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
function WithdrawDialog(props: { onClose: (updated: boolean) => void; agent: any; open: boolean }) {
  const { onClose, agent, open } = props

  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>출금</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="금액"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            value={amount}
            onChange={(event) => {
              setAmount(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="error"
            disabled={loading}
            onClick={async (e: any) => {
              console.log(amount)
              setLoading(true)
              await withdrawAgentBalance({ agentIdx: agent.idx, amount: Number(amount) })
              setLoading(false)
              onClose(true)
            }}
          >
            출금
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function CalculatePage() {
  const month = GetMonthString()

  const [needUpdate, setNeedUpdate] = useState(false)
  const [begin, setBegin] = useState(month.begin)
  const [end, setEnd] = useState(month.end)
  const [master, setMaster] = useState('')
  const [loading, setLoading] = useState(false)
  const [agents, setAgents] = useState<any>({})
  const [keys, setKeys] = useState<any[]>([])
  const [selectedAgent, setSelectedAgent] = React.useState<any>({})
  const [openContentsDialog, setOpenContentsDialog] = React.useState(false)
  const [openDepositDialog, setOpenDepositDialog] = React.useState(false)
  const [openWithdrawDialog, setOpenWithdrawDialog] = React.useState(false)

  console.log(`CalculatePage`)
  console.log(month.begin)
  console.log(month.end)

  function fetchCondtion() {
    return master !== ''
  }

  async function fetchData() {
    // if (fetchCondtion() === false) {
    //   return alert('검색조건 하나는 꼭 필요합니다.')
    // }

    setLoading(true)

    const params = {
      ...(master !== '' && { master }),
      ...{ begin },
      ...{ end },
    }

    try {
      const rawDatas: any = await getCalculate(params)
      console.log(rawDatas.data)

      // const datas = rawDatas.map((value) => {
      //   return { ...value, regdate: new Date(value.regdate), lastdate: new Date(value.lastdate) }
      // })

      // setKeys(keys)
      console.log(rawDatas.data)
      setAgents(rawDatas.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      alert('fetch error')
    }
  }

  if (needUpdate) {
    if (fetchCondtion()) {
      fetchData()
    }
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    // if (fetchCondtion()) {
    fetchData()
    // }
    //fetchData({date:currentDate})
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0px', alignSelf: 'flex-start' }}>
        <TextField
          id="date"
          label="시작"
          type="date"
          defaultValue={begin}
          sx={{ width: 300 }}
          // value={begin}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            setBegin(evt.target.value)
          }}
        />
        <TextField
          id="date"
          label="종료"
          type="date"
          defaultValue={end}
          sx={{ width: 300 }}
          value={end}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            setEnd(evt.target.value)
          }}
        />
        <TextField
          style={{ minWidth: '100px', margin: '0px 10px' }}
          id="outlined-basic"
          label="master"
          fullWidth
          value={master}
          onChange={(evt) => {
            setMaster(evt.target.value)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            fetchData()
          }}
        >
          {'검색'}
        </Button>
      </div>
      {MyResponsiveBar(agents)}
    </div>
  )
}

export default CalculatePage
