import React, { useState, useEffect } from 'react'

import { getCancelledBettings } from '../../api'
import { Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { BettingItem } from '../../api.interface'

console.log(process.env.REACT_APP_API)

function FakeBettingListPage() {
  const [loading, setLoading] = useState(false)
  const [totalBetMoney, setTotalBetMoney] = useState<number>(0)
  const [totalWinMoney, setTotalWinMoney] = useState<number>(0)
  const [totalWinRate, setTotalWinRate] = useState<number>(0)
  const [fakeBetMoney, setFakeBetMoney] = useState<number>(0)
  const [fakeWinMoney, setFakeWinMoney] = useState<number>(0)
  const [fakeWinRate, setFakeWinRate] = useState<number>(0)
  const [bettings, setBettings] = useState<BettingItem[]>([])

  async function fetchBettings(continueData = false) {
    try {
      setLoading(true)
      const rawData = await getCancelledBettings({
        //vendor: 'fhsvo',
        ...(continueData && bettings.length > 0 && { continueId: bettings[bettings.length - 1]._id }),
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawBettings = rawData.data

      const newBettings = [
        ...bettings,
        ...rawBettings.map((value) => ({
          ...value,
          betTime: new Date(value.betTime),
        })),
      ]

      let totalBetMoney = 0
      let totalWinMoney = 0
      let totalWinCount = 0
      let totalCount = 0
      let fakeBetMoney = 0
      let fakeWinMoney = 0
      let fakeWinCount = 0
      let fakeCount = 0

      for (const rawBetting of newBettings) {
        const amountBet = rawBetting.amountBet ?? 0
        const amountWin = rawBetting.amountWin ?? 0
        totalBetMoney += amountBet
        totalWinMoney += amountWin

        if (amountBet < amountWin) {
          totalWinCount++
        }
        if (amountBet !== amountWin) {
          totalCount++
        }

        if (rawBetting.isFakeBet) {
          fakeBetMoney += amountBet
          fakeWinMoney += amountWin

          if (amountBet < amountWin) {
            fakeWinCount++
          }
          if (amountBet !== amountWin) {
            fakeCount++
          }
        }
      }

      setTotalBetMoney(totalBetMoney)
      setTotalWinMoney(totalWinMoney)
      setTotalWinRate(totalWinCount / totalCount)
      setFakeBetMoney(fakeBetMoney)
      setFakeWinMoney(fakeWinMoney)
      setFakeWinRate(fakeWinCount / fakeCount)

      setBettings(newBettings)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchBettings()
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1200px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => {
            fetchBettings(true)
          }}
        >
          {`데이터 추가 로딩 (${bettings.length})`}
        </Button>
        <div style={{ padding: 10 }}>전체베팅머니 : {totalBetMoney.toLocaleString()}</div>
        <div style={{ padding: 10 }}>전체승리머니 : {totalWinMoney.toLocaleString()}</div>
        <div style={{ padding: 10 }}>전체 승률 : {(totalWinRate * 100).toLocaleString() + ' %'}</div>
        <div style={{ padding: 10 }}>페이크베팅머니 : {fakeBetMoney.toLocaleString()}</div>
        <div style={{ padding: 10 }}>페이크승리머니 : {fakeWinMoney.toLocaleString()}</div>
        <div style={{ padding: 10 }}>페이크 승률 : {(fakeWinRate * 100).toLocaleString() + ' %'}</div>
      </div>
      <DataGrid
        style={{ width: '100%', height: '100%' }}
        getRowId={(row) => row._id}
        rows={bettings}
        columns={[
          {
            field: 'betTime',
            headerName: 'betTime',
            width: 200,
            valueFormatter: (params) => params.value?.toLocaleString(),
          },
          {
            field: 'agentCode',
            headerName: 'agentCode',
            width: 80,
          },
          {
            field: 'userId',
            headerName: 'userId',
            width: 100,
          },
          {
            field: 'gameId',
            headerName: 'gameId',
            width: 170,
          },
          {
            field: 'roundId',
            headerName: 'roundId',
            width: 210,
          },
          {
            field: 'tableId',
            headerName: 'tableId',
            width: 160,
          },
          {
            field: 'tableName',
            headerName: 'tableName',
            width: 200,
          },
          {
            field: 'isFake',
            headerName: 'isFake',
            width: 70,
          },
          {
            field: 'amountBet',
            headerName: '베팅액',
            width: 110,
            valueFormatter: (params) => params.value?.toLocaleString(),
          },
          {
            field: 'amountWin',
            headerName: '승리액',
            width: 110,
            valueFormatter: (params) => params.value?.toLocaleString(),
          },
          {
            field: 'betStatus',
            headerName: '베팅상태',
            width: 90,
          },
        ]}
        pageSize={100}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        loading={loading}
      />
    </div>
  )
}

export default FakeBettingListPage
