import { useState, useEffect, Fragment } from 'react'

import { addMonths, format } from 'date-fns'
import { Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Button from '@mui/material/Button'
import { useLocation, useNavigate } from 'react-router-dom'

import { getNewGames, modifyGame, uploadGameImage } from '../../api'
import { getFileImage, MINIMUM_IMAGE_SIZE } from '../../util'
import { GameItem } from '../../api.interface'

console.log(process.env.REACT_APP_API)

type NewGameItem = Omit<GameItem, 'createdAt'> & { createdAt: Date }

function NewGamesPage() {
  const [games, setGames] = useState<NewGameItem[]>([])

  const location = useLocation()
  const navigate = useNavigate()

  const params = new URLSearchParams(location.search)

  const currentDate = params.get('month') ?? format(new Date(), 'yyyy-MM')

  console.log('currentDate : ' + currentDate)

  async function fetchGames(day: string) {
    try {
      const rawData = await getNewGames(day)
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const newGames = rawData.data.map((value) => ({ ...value, createdAt: new Date(value.createdAt) }))

      setGames(newGames)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  console.log('FakeDailyStatsPage render')

  useEffect(() => {
    console.log('useEffect')
    fetchGames(currentDate)
    //updateMonth(currentYearMonth)
  }, [])

  async function changeImage(file: File, id: string) {
    const image = await getFileImage(file)

    // 이미지를 webp로 변환
    const canvas = document.createElement('canvas')

    canvas.width = image.width
    canvas.height = image.height

    //이미지가 너무 크면 적당히 리사이징해서 webp로 저장한다.
    if (canvas.width >= canvas.height) {
      if (canvas.width > MINIMUM_IMAGE_SIZE.width) {
        canvas.height = Math.round((MINIMUM_IMAGE_SIZE.width / canvas.width) * canvas.height)
        canvas.width = MINIMUM_IMAGE_SIZE.width
      }
    } else {
      if (canvas.height > MINIMUM_IMAGE_SIZE.height) {
        canvas.width = Math.round((MINIMUM_IMAGE_SIZE.height / canvas.height) * canvas.width)
        canvas.height = MINIMUM_IMAGE_SIZE.height
      }
    }

    const ctx = canvas.getContext('2d')
    if (ctx == null) {
      return
    }
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height) // draw the image

    console.log('save image')
    // do some manipulations...
    const base64 = canvas.toDataURL('image/webp')

    const [, imageBase64] = base64.split(',')

    const res = await uploadGameImage(id, imageBase64)

    //saveFile(imageBase64, 'webp')
    if (res.status === 0) {
      const game = games.find((value) => value._id === id.toString())
      if (game != null) {
        game.imgUrl = res.data?.toString() ?? ''
        setGames([...games])
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', minWidth: 800, maxWidth: 1000 }}>
      <div style={{ alignSelf: 'center' }}>
        <Button
          onClick={() => {
            const lastMonth = addMonths(new Date(currentDate), -1)

            const params = new URLSearchParams(location.search)
            const month = format(lastMonth, 'yyyy-MM')
            params.set('month', month)

            console.log(params.toString())

            navigate('?' + params.toString())
          }}
        >
          {'<<'}
        </Button>
        {currentDate}
        <Button
          onClick={() => {
            const nextMonth = addMonths(new Date(currentDate), 1)

            const params = new URLSearchParams(location.search)
            const month = format(nextMonth, 'yyyy-MM')
            params.set('month', month)

            console.log(params.toString())

            navigate('?' + params.toString())
          }}
        >
          {'>>'}
        </Button>
      </div>
      <Table sx={{ maxWidth: 1200, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ minWidth: '180px' }}>
              생성일
            </TableCell>
            <TableCell align="center">벤더</TableCell>
            <TableCell align="center">브랜드</TableCell>
            <TableCell align="center">코드</TableCell>
            <TableCell align="center">영어이름</TableCell>
            <TableCell align="center">한국이름</TableCell>
            <TableCell align="center">사용여부</TableCell>
            <TableCell align="center">이미지</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games.map((row, index) => (
            <TableRow key={row._id}>
              <TableCell>{row.createdAt.toLocaleString()}</TableCell>
              <TableCell>{row.vendor}</TableCell>
              <TableCell>{row.brand}</TableCell>
              <TableCell align="left">{row.code}</TableCell>
              <TableCell align="left">{row.nameEn}</TableCell>
              <TableCell align="left">{row.nameKo}</TableCell>
              <TableCell align="center">
                <Switch
                  checked={row.used === 'y'}
                  onChange={async (event) => {
                    const res = await modifyGame({
                      _id: row._id.toString(),
                      setObj: {
                        used: row.used === 'y' ? 'n' : 'y',
                      },
                    })
                    if (res.status === 0) {
                      const game = games.find((value) => value._id === row._id.toString())
                      if (game != null) {
                        game.used = row.used === 'y' ? 'n' : 'y'
                        setGames([...games])
                      }
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                  }}
                >
                  <label htmlFor={row._id.toString() ?? ''}>
                    <img width="200" height="200" src={row.imgUrl} />
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    id={row._id.toString() ?? ''}
                    onChange={(event) => {
                      console.log(row)
                      console.log(event)
                      const files = event.target.files

                      if (files == null || files.length === 0) return
                      changeImage(files[0], row._id.toString())
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default NewGamesPage
