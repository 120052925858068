import React, { useState, useEffect } from 'react'

import { getFakeEntryErrorLogs } from '../../api'
import { Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DetailDialog } from '../../component/DetailDialog'

console.log(process.env.REACT_APP_API)

function FakeEntryErrorLogPage() {
  const [loading, setLoading] = useState(false)
  const [selectedLog, setSelectedLog] = useState<any>([])
  const [errorLogs, setErrorLogs] = useState<any[]>([])
  const [openDetailDialog, setOpenDetailDialog] = React.useState(false)

  async function fetchData() {
    try {
      setLoading(true)
      const rawData = await getFakeEntryErrorLogs()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const data = rawDatas.map((value) => ({
        ...value,
        loginStatus: value.stepLog?.login?.response?.status,
        loginData: value.stepLog?.login?.response?.data,
        loginError: value.stepLog?.login?.response?.error,
      }))

      setErrorLogs(data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
  }, [])

  const borderColor = 'lightgrey'

  const styles = {
    padding: '10px',
    margin: 0,
    borderRadius: '0 0 2px 2px',
    borderTop: 'solid 1px ' + borderColor,
  }

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1200px' }}
    >
      <DataGrid
        style={{ width: '100%', height: '100%' }}
        getRowId={(row) => row._id}
        rows={errorLogs}
        columns={[
          {
            field: 'timestamp',
            headerName: 'timestamp',
            width: 200,
            valueFormatter: (params) => params.value?.toLocaleString(),
          },
          {
            field: 'agentCode',
            headerName: 'agentCode',
            width: 80,
          },
          {
            field: 'userId',
            headerName: 'userId',
            width: 100,
          },
          {
            field: 'loginStatus',
            headerName: 'loginStatus',
            width: 170,
          },
          {
            field: 'loginData',
            headerName: 'loginData',
            width: 170,
            valueFormatter: (params) => JSON.stringify(params.value ?? {}),
          },
          {
            field: 'loginError',
            headerName: 'loginError',
            width: 170,
            valueFormatter: (params) => JSON.stringify(params.value ?? {}),
          },
          {
            field: 'detailAction',
            headerName: '상세',
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            width: 100,
            renderCell: (params) => {
              return (
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={(e: any) => {
                      e.stopPropagation() // don't select this row after clicking
                      setSelectedLog(params.row)
                      setOpenDetailDialog(true)
                    }}
                  >
                    상세
                  </Button>
                </div>
              )
            },
          },
        ]}
        pageSize={100}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        loading={loading}
      />

      <DetailDialog
        open={openDetailDialog}
        content={selectedLog}
        onClose={(updated: boolean) => {
          setOpenDetailDialog(updated)
        }}
      />
    </div>
  )
}

export default FakeEntryErrorLogPage
