import { useState, useEffect } from 'react'

import { getFakeSeamless } from '../../api'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { FakeSeamlessStat } from '../../api.interface'

console.log(process.env.REACT_APP_API)

function FakeSeamlessPage({ cluster, serviceName, region }: { cluster: string; serviceName: string; region: string }) {
  const [socketServers, setSocketServers] = useState<FakeSeamlessStat[]>([])

  console.log('FakeSeamlessPage')

  async function fetchData() {
    try {
      const res = await getFakeSeamless({ cluster, serviceName, region })
      console.log(res)

      const servers = (res.data ?? []).map((value) => ({ ...value, updatedAt: new Date(value.updatedAt) }))

      if (res.data == null) {
        throw 'error packet'
      }

      setSocketServers(servers)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    console.log('FakeSocketPage useEffect')
    fetchData()
    return () => {
      console.log('FakeSocketPage useEffect2')
    }
  }, [])

  const now = new Date()

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%' }}>
      <div>
        <Grid container>
          {socketServers.map((row) => (
            <Card sx={{ minWidth: 200 }} key={row._id}>
              <CardContent>
                <Typography sx={{ fontSize: 16 }} component="div">
                  {row._id}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'상태 : ' + (row.status ?? '종료됨')}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'작업Id : ' + (row.taskId ?? '없음')}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'사용 메모리 : ' + (row.rss ?? '없음')}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {'업데이트 시간 : ' + row.updatedAt.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default FakeSeamlessPage
