import React, { useState, useEffect } from 'react'

import { getFakeConnectionLogs } from '../../api'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { FakeConnectionLog } from '../../api.interface'
import { useSearchParams } from 'react-router-dom'

console.log(process.env.REACT_APP_API)

function FakeConnectionLogPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const username = searchParams.get('username')
  const [tempUsername, setTempUsername] = useState(username)
  console.log('FakeConnectionLogPage : ' + username)
  const [needUpdate, setNeedUpdate] = useState(false)

  const [logs, setLogs] = useState<FakeConnectionLog[]>([])

  async function fetchData() {
    try {
      const rawData = await getFakeConnectionLogs({
        ...(username != null && { username }),
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const data = rawDatas.map((value) => ({
        ...value,
        connectionTime: new Date(value.connectionTime),
      }))

      setLogs(data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1200px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          width: '100%',
        }}
      >
        <TextField
          style={{ maxWidth: '150px', margin: '0px 10px' }}
          id="outlined-basic"
          label="username"
          fullWidth
          value={tempUsername}
          onChange={(evt) => {
            setTempUsername(evt.target.value as string)
            //searchParams.set('username', evt.target.value as string)
            //setSearchParams(searchParams)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            searchParams.set('username', tempUsername ?? '')
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
      </div>
      <Table sx={{ marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ width: '90px' }}>
              시간
            </TableCell>
            <TableCell align="center">유저이름</TableCell>
            <TableCell align="center">아이피</TableCell>
            <TableCell align="center">접속 url</TableCell>
            <TableCell align="center">UA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((row) => (
            <TableRow key={row._id}>
              <TableCell align="center">{row.connectionTime.toLocaleString()}</TableCell>
              <TableCell align="center">{row.username?.toString()}</TableCell>
              <TableCell align="center">{row.ip?.toString()}</TableCell>
              <TableCell align="center">{row.url?.toString()}</TableCell>
              <TableCell align="center">{JSON.stringify(row.userAgent).toString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default FakeConnectionLogPage
