import React, { useState, useEffect } from 'react'

import { ObjectId } from 'bson'

import { getEvoTables, getGameRounds } from '../../api'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useSearchParams } from 'react-router-dom'
import { EvoTable, FakeGameData } from '../../api.interface'

console.log(process.env.REACT_APP_API)

type NewFakeGameData = Omit<FakeGameData, 'resultTime'> & { resultTime: Date; startTime: Date }

function DetailDialog(props: { onClose: (updated: boolean) => void; detail?: NewFakeGameData; open: boolean }) {
  const { onClose, detail, open } = props

  const handleClose = () => {
    onClose(false)
  }
  const borderColor = 'lightgrey'

  const styles = {
    padding: '10px',
    margin: 0,
    borderRadius: '0 0 2px 2px',
    borderTop: 'solid 1px ' + borderColor,
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xl">
      <DialogTitle>상세</DialogTitle>
      <DialogContent>
        <pre style={styles}>
          <div style={{ paddingBottom: '20px', width: '300px' }}>
            <Table style={{ border: '1px solid #444444', borderCollapse: 'collapse' }}>
              <TableHead>
                <th></th>
                <th>플레이어</th>
                <th>뱅커</th>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>카드</TableCell>
                  <TableCell>{detail?.playerHand?.cards.join(' ')}</TableCell>
                  <TableCell>{detail?.bankerHand?.cards.join(' ')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>점수</TableCell>
                  <TableCell>{detail?.playerHand?.score}</TableCell>
                  <TableCell>{detail?.bankerHand?.score}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {detail?.lightningMultipliers && (
            <div>
              라이트닝
              <Table style={{ border: '1px solid #444444', borderCollapse: 'collapse' }}>
                <TableBody>
                  <TableRow>
                    <TableCell>카드</TableCell>
                    {detail.lightningMultipliers.map((value: any) => (
                      <TableCell>{value.card}</TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>배수</TableCell>
                    {detail.lightningMultipliers.map((value: any) => (
                      <TableCell>{value.value}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
        </pre>
        <pre style={styles}>
          <code>{JSON.stringify(detail, null, 2)}</code>
        </pre>
      </DialogContent>
    </Dialog>
  )
}

function FakeGameRoundPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const tableId = searchParams.get('tableId')
  console.log('FakeGameRoundPage : ' + tableId)

  const [loading, setLoading] = useState(false)
  const [needUpdate, setNeedUpdate] = useState(false)
  const [rounds, setRounds] = useState<NewFakeGameData[]>([])
  //const [evoTables, setEvoTables] = useState<EvoTable[]>([])
  const [evoTables, setEvoTables] = useState<{ [key: string]: EvoTable }>({})
  const [selectedRound, setSelectedRound] = useState<NewFakeGameData>()
  const [openDetailDialog, setOpenDetailDialog] = useState(false)

  async function fetchTables() {
    try {
      const rawData = await getEvoTables()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const tables = rawData.data.reduce<{ [key: string]: EvoTable }>((prev, value) => {
        prev[value.tableId] = value
        return prev
      }, {})

      setEvoTables(tables)

      return tables
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
      throw err
    }
  }

  async function fetchGameRounds() {
    try {
      setLoading(true)

      const tables = Object.keys(evoTables).length > 0 ? evoTables : await fetchTables()

      console.log(tableId)
      const rawData = await getGameRounds({
        //vendor: 'fhsvo',
        ...(tableId != null && { tableId }),
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setRounds(
        rawData.data.map((value) => ({
          ...value,
          tableName: tables[value.tableId]?.tableName ?? '',
          resultMessage: '플 ' + value.playerHand.score + ' : ' + value.bankerHand.score + ' 뱅',
          startTime: new Date(new ObjectId(value._id).getTimestamp()),
          resultTime: new Date(value.resultTime),
        })),
      )
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchTables()
    fetchGameRounds()
  }, [])

  if (needUpdate) {
    setNeedUpdate(false)
    fetchGameRounds()
  }

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1200px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tableId}
          label="테이블"
          onChange={(event) => {
            searchParams.set('tableId', event.target.value as string)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {Object.values(evoTables).map((value) => (
            <MenuItem key={value.tableId} value={value.tableId}>
              {`${value.tableName} (${value.tableId})`}
            </MenuItem>
          ))}
        </Select>
      </div>
      <DataGrid
        style={{ width: '100%', height: '100%' }}
        getRowId={(row) => row._id}
        rows={rounds}
        columns={[
          {
            field: 'startTime',
            headerName: 'startTime',
            width: 200,
            valueFormatter: (params) => params.value?.toLocaleString(),
          },
          {
            field: 'resultTime',
            headerName: 'resultTime',
            width: 200,
            valueFormatter: (params) => params.value?.toLocaleString(),
          },
          {
            field: 'tableId',
            headerName: 'tableId',
            width: 200,
          },
          {
            field: 'gameId',
            headerName: 'gameId',
            width: 220,
          },
          {
            field: 'tableName',
            headerName: 'tableName',
            width: 200,
          },
          {
            field: 'resultMessage',
            headerName: '결과',
            width: 100,
          },
          {
            field: 'detailAction',
            headerName: '상세',
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            renderCell: (params) => {
              return (
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={(e: any) => {
                      e.stopPropagation() // don't select this row after clicking
                      setSelectedRound(params.row)
                      setOpenDetailDialog(true)
                    }}
                  >
                    상세
                  </Button>
                </div>
              )
            },
          },
        ]}
        pageSize={100}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        loading={loading}
      />
      <DetailDialog
        open={openDetailDialog}
        detail={selectedRound}
        onClose={(updated: boolean) => {
          setOpenDetailDialog(updated)
        }}
      />
    </div>
  )
}

export default FakeGameRoundPage
