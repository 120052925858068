import React, { useState, useEffect } from 'react'

import { getFakeBet } from '../api'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { BettingItem, FakeBetData, FakeGameData } from '../api.interface'

interface DetailEvent {
  time: Date
  event: string
  content: string
}

export function FakeDetailDialog(props: {
  onClose: (updated: boolean) => void
  roundId: string
  username: string
  open: boolean
}) {
  const { onClose, roundId, username, open } = props

  const [bet, setBet] = useState<BettingItem>()
  const [fakeBet, setFakeBet] = useState<FakeBetData>()
  const [gameData, setGameData] = useState<FakeGameData>()
  const [events, setEvents] = useState<DetailEvent[]>([])

  const handleClose = () => {
    onClose(false)
  }

  const borderColor = 'lightgrey'

  const styles = {
    padding: '10px',
    margin: 0,
    borderRadius: '0 0 2px 2px',
    borderTop: 'solid 1px ' + borderColor,
  }

  const updateDetail = async () => {
    try {
      if (roundId == null || username == null) {
        return
      }

      const rawData = await getFakeBet({ roundId, username })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      setBet(rawDatas.bet)
      setGameData(rawDatas.game)
      setFakeBet(rawDatas.fake)

      const events: DetailEvent[] = [
        ...Object.entries(rawDatas.fake?.saveBet ?? {}).map(([key, value]) => {
          const { action } = value.args
          const event = action.name

          const time = new Date(Number(key))

          if (event === 'Chips') {
            return {
              time,
              event: '칩 걸기',
              content: JSON.stringify(action.chips),
            }
          } else if (event === 'Chip') {
            return {
              time,
              event: '칩 걸기',
              content: action.chip.spot + ' : ' + action.chip.amount,
            }
          } else if (event === 'Undo') {
            return {
              time,
              event: '되돌리기',
              content: '',
            }
          } else if (event === 'UndoAll') {
            return {
              time,
              event: '모두 되돌리기',
              content: '',
            }
          } else if (event === 'Repeat') {
            return {
              time,
              event: '다시 베팅',
              content: '',
            }
          } else if (event === 'Double') {
            return {
              time,
              event: '더블',
              content: '',
            }
          } else if (event === 'Move') {
            return {
              time,
              event: '칩 이동',
              content: action.betSpotFrom + ' -> ' + action.betSpotTo,
            }
          }

          return {
            time,
            event: action.name,
            content: JSON.stringify(action),
          }
        }),
        ...Object.values(rawDatas.game?.packet ?? {}).map((value) => {
          const { betting, dealing, gameData } = value.args
          const time = new Date(value.time)
          const content = betting + ' ' + dealing
          if (betting === 'BetsOpen') {
            return {
              time: time,
              event: '베팅 시작',
              content,
            }
          } else if (dealing === 'Idle') {
            return {
              time: time,
              event: '베팅 종료',
              content,
            }
          } else if (dealing === 'Dealing') {
            return {
              time: time,
              event: '딜링',
              content,
            }
          } else if (dealing === 'Lightning') {
            return {
              time: time,
              event: '라이트닝',
              content,
            }
          } else if (dealing === 'Finished') {
            return {
              time: time,
              event: `라운드 종료 결과`,
              content,
            }
          } else if (dealing === 'Cancelled') {
            return {
              time: time,
              event: '취소된 라운드',
              content,
            }
          }
          return {
            time: time,
            event: '알수없는 게임상태',
            content,
          }
        }),
      ].sort((a, b) => a.time.getTime() - b.time.getTime())

      setEvents(events)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  useEffect(() => {
    // open으로 열렸을 때만 데이터를 가져온다.
    if (open) {
      updateDetail()
    }
  }, [open])

  const { playerHand, bankerHand } = gameData ?? {}

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xl">
      <DialogTitle>상세</DialogTitle>
      <DialogContent>
        <pre style={styles}>
          <div style={{ paddingBottom: '20px', width: '300px' }}>
            <Table style={{ border: '1px solid #444444', borderCollapse: 'collapse' }}>
              <TableHead>
                <th></th>
                <th>플레이어</th>
                <th>뱅커</th>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>카드</TableCell>
                  <TableCell>{playerHand?.cards.join(' ')}</TableCell>
                  <TableCell>{bankerHand?.cards.join(' ')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>점수</TableCell>
                  <TableCell>{playerHand?.score}</TableCell>
                  <TableCell>{bankerHand?.score}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {gameData?.lightningMultipliers && (
            <div>
              라이트닝
              <Table style={{ border: '1px solid #444444', borderCollapse: 'collapse' }}>
                <TableBody>
                  <TableRow>
                    <TableCell>카드</TableCell>
                    {gameData.lightningMultipliers.map((value: any) => (
                      <TableCell>{value.card}</TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>배수</TableCell>
                    {gameData.lightningMultipliers.map((value: any) => (
                      <TableCell>{value.value}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
        </pre>
        <pre style={styles}>
          <Table style={{ border: '1px solid #444444', borderCollapse: 'collapse', width: '300px' }}>
            <TableHead>
              <th>시간</th>
              <th>이벤트</th>
              <th>내용</th>
            </TableHead>
            <TableBody>
              {events.map((value) => (
                <TableRow>
                  <TableCell>{value.time.toLocaleString()}</TableCell>
                  <TableCell>{value.event}</TableCell>
                  <TableCell>{value.content}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </pre>
        {bet != null && (
          <pre style={styles}>
            <Typography variant="h5">베팅 상세</Typography>
            <code>{JSON.stringify(bet, null, 2)}</code>
          </pre>
        )}
        {fakeBet != null && (
          <pre style={styles}>
            <Typography variant="h5">페이크 베팅 상세</Typography>
            <code>{JSON.stringify(fakeBet, null, 2)}</code>
          </pre>
        )}
        {gameData != null && (
          <pre style={styles}>
            <Typography variant="h5">게임 상세</Typography>
            <code>{JSON.stringify(gameData, null, 2)}</code>
          </pre>
        )}
      </DialogContent>
    </Dialog>
  )
}
