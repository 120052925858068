import { useState, useEffect } from 'react'
import { getAgents, getAgentGameSetting, setAgentVendorConfigValue, setAgentVendorUsed, getVendors } from '../../api'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { Agent, AgentVendorSetting, VendorItem } from '../../api.interface'

console.log(process.env.REACT_APP_API)

function AgentVendorSettingPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const agentIdParam = searchParams.get('agent')
  console.log('AgentVendorSettingPage : ' + agentIdParam)

  const [needUpdate, setNeedUpdate] = useState(false)
  const [settings, setSettings] = useState<{
    [vendorCode: string]: AgentVendorSetting
  }>({})
  const [parentSettings, setParentSettings] = useState<{
    [vendorCode: string]: AgentVendorSetting
  }>({})
  const [vendors, setVendors] = useState<VendorItem[]>([])
  const [selectedAgent, setSelectedAgent] = useState<Agent>()
  const [agents, setAgents] = useState<Agent[]>([])

  console.log('AgentVendorSettingPage : ' + agentIdParam)

  async function fetchData() {
    console.log('fetchData', agentIdParam)
    if (agentIdParam == null) {
      return
    }

    try {
      const rawData = await getAgentGameSetting({ agentId: agentIdParam })
      console.log(rawData)

      if (rawData.status !== 0) {
        throw 'getAgentGameSetting error packet'
      }

      const rawDatas = rawData.data

      let parentMergedVendorSettings: {
        [vendorCode: string]: AgentVendorSetting
      } = {}

      let settings = {}

      for (const agentGameSetting of rawDatas.agentGameSettings) {
        const { vendorGameSettings } = agentGameSetting

        if (agentGameSetting.agentId === agentIdParam) {
          settings = vendorGameSettings ?? {}
          continue
        }
        if (vendorGameSettings == null || agentGameSetting.agentId === agentIdParam) {
          continue
        }

        parentMergedVendorSettings = {
          ...parentMergedVendorSettings,
          ...vendorGameSettings,
        }
      }

      setSelectedAgent(rawDatas?.agent ?? {})
      setParentSettings(parentMergedVendorSettings)
      setSettings(settings)
      if (vendors.length === 0) {
        fetchVendors()
      }
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  async function fetchAgents() {
    try {
      if (agents?.length !== 0) {
        return
      }
      const rawData = await getAgents({})
      console.log(rawData)

      if (rawData.status !== 0) {
        throw 'getAgents error packet'
      }

      setAgents(rawData.data.filter((value) => value.status === 'reg'))
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }
  async function fetchVendors() {
    try {
      if (vendors?.length !== 0) {
        return
      }
      const rawData = await getVendors()
      console.log(rawData)

      if (rawData.status !== 0) {
        throw 'getVendors error packet'
      }

      setVendors(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
    fetchAgents()
    //fetchData({date:currentDate})
  }, [])

  return (
    <div
      className="Page"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1300px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '10px 0px',
          marginLeft: '10px',
          width: '100%',
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={agentIdParam}
          label="Agent"
          onChange={(event) => {
            searchParams.set('agent', event.target.value as string)
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {agents.map((value) => (
            <MenuItem key={value.agentId} value={value.agentId}>
              {`${value.agentId} (${value.nick})`}
            </MenuItem>
          ))}
        </Select>
        <div style={{ marginLeft: '50px' }}>에이전트 트리</div>
        <div style={{ marginLeft: '10px' }}>
          {selectedAgent?.agentTree
            .split('|')
            .filter((value) => value !== '')
            .map((value) => (
              <span
                onClick={() => {
                  searchParams.set('agent', value)
                  setSearchParams(searchParams)
                  //location.reload()
                  setNeedUpdate(true)
                }}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {value}
              </span>
            ))
            .reduce<JSX.Element[]>((accu, elem) => {
              return accu.length === 0 ? [elem] : [...accu, <span> {`>`} </span>, elem]
            }, [])}
        </div>
      </div>
      <Table sx={{ maxWidth: 800, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">벤더 코드</TableCell>
            <TableCell align="center">영어 이름</TableCell>
            <TableCell align="center">한글 이름</TableCell>
            <TableCell align="center">설명</TableCell>
            <TableCell align="center">상위 사용 여부</TableCell>
            <TableCell align="center">사용 여부</TableCell>
            <TableCell align="center">게임 설정</TableCell>
            <TableCell align="center">설정</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agentIdParam != null &&
            vendors.map((row) => (
              <TableRow key={row.code}>
                <TableCell align="left">{row.code}</TableCell>
                <TableCell align="left">{row.nameEn}</TableCell>
                <TableCell align="left">{row.nameKo}</TableCell>
                <TableCell align="left">{row.desc}</TableCell>
                <TableCell align="left">
                  <Switch checked={parentSettings[row.code]?.used !== false} disabled={true} />
                </TableCell>
                <TableCell align="left">
                  <Switch
                    checked={settings[row.code]?.used !== false}
                    onChange={async (event) => {
                      if (agentIdParam == null) return

                      const changeUsed = settings[row.code]?.used !== false ? false : true
                      const res = await setAgentVendorUsed({
                        agentId: agentIdParam,
                        vendor: row.code,
                        used: changeUsed,
                      })
                      if (res.status === 0) {
                        /*const setting = settings[row.code]
                        console.log(`update settings ${row.code} ${changeUsed}`)
                        if (setting != null) {
                          setting.used = changeUsed
                          setSettings({ ...settings })
                        }*/

                        //상위 에이전트의 설정도 확인해야 되서 데이터를 다시 불러온다.
                        fetchData()
                      }
                    }}
                  />
                </TableCell>
                <TableCell width="120px" align="center">
                  <div>
                    <Button variant="contained" href={`#/agent-game-setting?agent=${agentIdParam}&vendor=${row.code}`}>
                      게임설정
                    </Button>
                  </div>
                </TableCell>
                <TableCell width="120px" align="left">
                  <div>
                    {Object.entries(row.configSchema?.optionalProperties ?? {}).map(([name, schema]) => {
                      if (schema.enum != null) {
                        const config = settings[row.code]?.config ?? {}
                        const configValue = config[name]
                        console.log('schema enum', row.code, name, JSON.stringify(schema), configValue)
                        return (
                          <FormControl fullWidth key={row.code + '-' + name}>
                            <InputLabel id="demo-simple-select-label">{name}</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={configValue}
                              label={name}
                              onChange={async (event) => {
                                if (agentIdParam == null) return

                                console.log(configValue)
                                console.log(event.target.value)
                                await setAgentVendorConfigValue({
                                  agentId: agentIdParam,
                                  vendor: row.code,
                                  config: {
                                    key: name,
                                    value: event.target.value,
                                  },
                                })
                                const setting = settings[row.code] ?? {}
                                setting.config ??= {}
                                setting.config[name] = event.target.value
                                setSettings({ ...settings })
                              }}
                            >
                              {schema.enum.map((value) => (
                                <MenuItem key={value} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )
                      }
                      return <div key={name}>{`${name} ${JSON.stringify(schema)}`}</div>
                    })}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AgentVendorSettingPage
